/* Common CSS */
@import url("https://fonts.googleapis.com/css2?family=Hind:wght@300;500;600;700&display=swap");
body, p{
     font-family: "Hind", sans-serif;
}
h1, h2, h3, h4,  h5, h6 {
     font-family: "Hind", sans-serif;
    font-weight: 500
}
a, button, .btn input, textarea, select {
    outline: 0 !important;
    box-shadow: none !important;
}
.space-cls {
    margin: 15px 0;
}
.item {
    padding: 0.2px;
}
@media (min-width: 1480px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1420px;
    }
    .col_5 {
        flex: 0 0 20%;
    }
}
@media (min-width: 1680px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1620px;
    }
}
img {
    max-width: 100%;
    font-size: 10px;
}
.heading-type1 {
    color: #000;
    font-size: 22px;
    margin-bottom: 0;   
}
.primary_btn {
    background: #FC2779;
}
.primary_btn:hover {
    background: #5ad36e;
}
.btn {
    font-size: 15px;
    color: #fff;
    height: 35px;
    display: inline-flex;
    align-items: center;
    border-radius: 7px;
    padding: 10px 25px;
    line-height: 1;
}
/* Header CSS */
.top_header {
    background: url(../img/header-bg.png);
    padding: 10px 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.offer_badge {
    background: url(../img/offer-badge.svg);
    padding: 10px 20px;
    background-size: 100%;
    background-position: center;
    font-size: 14px;
    color: #fff;
    background-repeat: no-repeat;
    /* margin-right: 10px; */
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    line-height: 1;
}
span.navbar-toggler-icon {
    background-image: url(../img/menu.svg);
    background-size: 20px;
}
.top_header .container {
    display: flex;
    align-items: center;
}
.header_contact_info {
    display: flex;
    border-radius: 7px;
    margin-right: 25px;
}
.header_right {
    margin-left: auto;
    display: flex;
    align-items: center;
}
.social-cls ul {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding-left: 0;
}
.header_list {
   padding: 0 20px;
   border-right: solid 1px #414141;
}
.header_list:last-child {
    border-right: 0;
    padding-right: 0
}
.header_list a {
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 14px;
}
nav.navbar {
    padding: 0;
}
.head_icon {
    margin-right: 10px;
}

.head_top_cont a {
    color: #000000;
    text-decoration: none;
    font-size: 16px;
}
.middle_header .container {
    display: flex;
    position: relative;
    align-items: center;
}
.middle_header {
    padding: 10px 0;
}
.menu-right {
    margin-left: 20px;
    width:100%;
}
.search-wrap {
    margin-left: auto;
    display: flex;
    justify-content: space-between;
}
.head-right {
    display: flex;
    margin-left: auto;
    align-items: center;
}
.search-wrap .form-group {
    margin-left: auto;
    margin-bottom: 0;
}
.head_icon img {
    filter: invert(1);
}
.head-right ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}
.head-right ul li {
    margin: 0 10px;
    position: relative;
}
.count_cls {
    background: #f07;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    position: absolute;
    top: -5px;
    justify-content: center;
    color: #fff;
    font-size: 11px;
    right: -5px;
    line-height: 1;
}
.head-right ul li:last-child {
    margin-right: 0;
}
.head-right ul li a {
    display: block;
}
/* Owl Carousel CSS */
.owl-theme .owl-nav {
    margin-top:0 !important;
}
.owl-carousel .owl-nav div {
    position: absolute;
    top: calc(50% - 25px);
    font-size: 0 !important;
    background: #d7fbda8f url(../img/arrow-left.png) !important;
    height: 45px;
    width: 45px;
    box-shadow: none !important;
    outline: 0 !important;
    border-radius: 50% !important;
    background-repeat: no-repeat !important;
    left: 20px;
    background-position: center !important;
}
.owl-carousel .owl-nav .owl-next {
    right: 20px !important;
    left: auto !important;
    background: #d7fbda8f url(../img/arrow-right.png) !important;
    box-shadow: none !important;
    outline: 0 !important;
    border-radius: 50% !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.search-wrap .form-control {
    background: #F2F2F2 !important;
    min-width: 700px !important;
    border-radius: 10px !important;
    min-height: 40px;
    font-size: 14px;
    padding-right: 65px;
    border:0;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 14%);
}
.search-icon {
    position: absolute;
    z-index: 11;
    left: 10px;
    top: calc(50% - 12px);
}
.search-wrap .form-control:focus {
    border: 0;
    outline: 0;
    background: #F2F2F2 !important;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 14%) !important;
}
 .form-control:focus  {
    outline: 0 ;
    box-shadow: none !important;
 }
.header_login_cls select {
    background: #FC2779;
    border: #FC2779;
    border-radius: 4px;
    padding: 3px 10px;
    box-shadow: none;
    color: #fff;
}
.others-option {
    padding: 0;
    background-size: cover !important;
    background-position: center;
    object-fit: cover !important;
    background-repeat: no-repeat;
    margin-right: 0;
    display: flex;
    align-items: center;
}
.others-option .offer {
    padding: 3px 0;
    margin-right: 0;
}
button.dropdown-toggle.dropdown-toggle.btn.btn-primary::after {
    display: none;
}
#countdown ul {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    justify-content: center;
}
#countdown ul li {
    padding: 0 2px;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
}
.navbar-light .navbar-nav .nav-link {
    color: #262626;
    font-size: 16px;
    padding: 8px 25px;
    text-transform: uppercase;
}
.navbar-light .navbar-nav li:first-child .nav-link {
    padding-left: 0;
}
.navbar-light .navbar-nav .nav-link:hover {
    color: #f91f7a;
}
.bottom_header {
    border-bottom: solid 1px #D8D8D8;
    border-top: solid 1px #D8D8D8;
}
.discount-cls {
    background: #EAE9FE;
    padding: 10px 0;
}
.disc-code p {
    margin-bottom: 0;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.disc-code p .coupon-code {
    background: #fff;
    padding: 2px 7px 0;
    border-radius: 30px;
    border: dashed 1px #fa3f8d;
    margin: 0 10px;
    color: #FC2779;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.1;
}
/* Timer Sec CSS */
.timers {
    background: #FC2779;
    position: absolute;
    top: 0;
    width: auto;
    padding: 10px;
    border-radius: 15px 0 3px 0;
}
.timers ul {
    margin-bottom: 0;
    padding-left: 0;
    display: flex;
    list-style: none;
}
.timers ul li {
    padding: 0 5px;
    font-size: 30px;
    color: #fff;
    font-weight:500;
}
.timers ul li span {
    font-size: 30px;
    color: #fff;
}
/* Category Sec CSS */
.category-sec .owl-prev {
    left: -25px !important;
}
.category-sec .owl-carousel .owl-nav .owl-next {
    right: -25px !important;
}
.offer-img {
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    padding-bottom: 18%;
}
.offer-img img {
    object-fit: cover;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100%;
    max-height: 100%;
    width: 100% !important;
}
/* Brand Sec CSS */
.brand-cls {
    position: relative;
}
.brand-img {
    position: relative;
    border-radius: 15px;
    padding-bottom: 67%;
    overflow: hidden;
}
.brand-img img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100%;
    max-height: 100%;
    width: 100% !important;
}
.title-main {
    margin-bottom: 10px;
}
.brand-cont {
    position: absolute;
    bottom: 10px;
    background: #fff;
    width: calc(100% - 30px);
    margin: 0 15px;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgb(0 0 0 / 15%);
}
.brand-cont p {
    margin-bottom: 0;
    font-size: 13px;
}
.brand-tag {
    font-size: 15px !important; 
    color: #FC2779;
    font-weight: 500;
}

/* Trending Product */
.title-bg {
    display: flex;
    align-items: center;
    justify-content: center;
}
.trending-product .heading-type2 {
    background-image: url(../img/shape2.png);
    background-size: contain !important;
    background-repeat: no-repeat !important;
    padding: 5px 35px;
    background-position: center;
}

.heading-type2 {
    font-size: 20px;
    margin-bottom: 0;
    font-weight: 500;
    color: #fff;
}
.bg-1 {
    background: #FFE1EC;
    border-radius: 15px;
    padding: 10px;
}
.title-center {
    margin-bottom: 10px;
    position: relative;
}
.prd-wrap .prd-title {
    position: absolute;
    bottom: 5px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 0
 }
.prd-wrap  .prd-title h4 {
    background-image: url(../img/shape3.png);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    padding: 5px 20px;
    display: inline-block;
    font-size: 16px;
    color: #fff;
    margin-bottom: 0
}

.trending-wrap {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
    top: -5px
}
.prd-box {
    background: #fff;
    padding: 0;
    border-radius: 15px;
    position: relative;
    border: solid 5px #FFA4C7;
}
.prd-img a {
    position: relative;
    border-radius: 15px;
    display: block;
    overflow: hidden;
}
.prd-wrap {
    position: relative;
}
.discount-listing .brand-img {
    padding-bottom: 55%;
}

/* Price Sec CSS */
.price-sec .row {
    margin: 0 -7.5px;
}
.price-sec .row [class*="col-"] {
    padding: 0 7.5px;
}
.price_bg {
    display: block;
    padding: 10px;
    text-decoration: none;
    border-radius: 10px;
    background: #f1f1f1;
}
.price_bg p {
    margin-bottom: 0;
}
.price_bg p span {
    display: block;
    text-align: center;
}
.price_bg:hover {
    color: #f91f7a;
}
.price_bg1 {
    background: #FFE4EE;
    color: #FC2779;
}
.under {
    font-size: 14px;
    font-weight: 500;
}
.price-dtl {
    font-size: 36px;
    font-weight: bold;
    line-height: 1.2;
}
.price-desc {
    font-size: 16px;
    font-weight: 500;
}
.price_bg2 {
    background: #FFF1DB;
    color: #F0B553;
}
.price_bg3 {
    color: #6AADCF;
    background: #DDEDF5;
}
.price_bg4 {
    background: #D7FBDA;
    color: #43CB50;
}
.price_bg5 {
    background: #EAE9FE;
    color: #9C99EE;
}
/* Flower Sec CSS */
.flower-sec .heading-type2 {
    background-image: url(../img/shape4.png);
    background-size: cover !important;
    background-repeat: no-repeat !important;
    padding: 5px 35px;
    background-position: center;
}
.bg-2 {
    background: #EAE9FE;
    padding: 10px;
    border-radius: 15px;
}

.flower-bg {
    background: #fff;
    padding: 0;
    border-radius: 20px;
    border: solid 5px #BCB9FC;
}
.flower-img a img {
    border-radius: 15px;
}

/* Gift Sec CSS */
.gift-banner {
    position: relative;
}
.gift-title {
    position: absolute;
    z-index: 11;
    bottom: 10px;
    background: url(../img/shape5.png);
    width: calc(100% - 30px);
    margin: 0px 15px;
    padding: 10px;
    text-align: center;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
}
.gift-title h5 {
    font-size: 15px !important;
    font-weight: bold;
    color: #fff;
    margin-bottom: 0px;
}
.gift-title h6 {
    color: #fff;
    font-size: 14px;
    margin-bottom: 0;
}
.gift-title h6 span {
    font-weight: bold;
}
/* New Arrival Sec CSS */
.arrival-bg {
    background: #D4FBD7;
    padding: 10px;
    border-radius: 15px;
}
.new-arrival-sec .heading-type2 {
    background-image: url(../img/shape6.png);
    background-size: cover !important;
    background-repeat: no-repeat !important;
    padding: 5px 35px;
    background-position: center;
}
.ratting-icon img {
    width: auto !important;
}
.arrival-prd {
    background: #fff;
    /* padding: 10px; */
}
.ratting-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}
.rate-dtl {
    margin-left: 5px;
    color: #707070;
    font-size: 12px;
    font-weight: bold;
    padding-top: 0;
}
.arrival-prd-img a {
    position: relative;
    display: flex;
    padding-bottom: 100%;
    align-items: center;
    justify-content: center;
}
.arrival-prd-img a img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
}
.arrival-prd-img {
    margin-bottom: 10px;
}
a.btn_primary.notify_me_new {
    background: #7d7fc0;
    border-color: #7d7fc0;
    /* d: unset; */
}
.arrival-title h3 {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.arrival-title p {
    font-size: 13px;
    color: #414141;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 0;
}
.arrival-price p {
    color: #303030;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
}
.arrival-price p.discount-price {
    /* text-decoration: line-through; */
    color: #414141;
    font-weight: 500;
    font-size: 13px;
}
.prd-add-option {
    display: flex;
    margin-top: 0;
    padding: 0 10px 10px;

}
.fav_wrap a {
    height: 40px;
    width: 40px !important;
    background: #f91f7a;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1px;
}
.fav_wrap {
    display: block;
}
.fav_wrap a img {
    width: auto !important;
}
.cart_wrap {
    width: 100%;
}
.btn_primary {
    background: #f91f7a;
    border-color: #f91f7a;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    text-decoration: none;
    text-transform: uppercase;
}
.arrival-cont {
    min-height: 110px;
    padding: 0 10px;
}
.out-of-stock {
    position: absolute;
    z-index: 11;
    left: 0;
    top: 10px;
}
.trending_badge {
    position: absolute;
    left: 0;
    top: 20px;
    z-index: 1;
}

/* Best saller banner sec CSS */
.banner-seller .brand-cont {
    max-width: 800px;
    left: calc(50% - 400px);
}
.banner-seller a {
    position: relative;
    display: block;
    padding-bottom: 20%;
    border-radius: 15px;
    overflow: hidden;
}
.banner-seller a img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100%;
    max-height: 100%;
    width: 100% !important;
    object-fit: cover;
    height: 100%;
}
/* Essentials Sec CSS */
.essentials-sec .heading-type2 {
    background-image: url(../img/shape2.png);
    background-size: contain !important;
    background-repeat: no-repeat !important;
    padding: 5px 35px;
    background-position: center;
}
.essentials-box {
    background: #fff;
    padding: 10px;
    border-radius: 10px;
}
.essentials-box h4 {
    color: #000;
    font-size: 21px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: 500;
}
.essentials-box .prd-img a {
    position: relative;
    padding-bottom: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.essentials-box .prd-img a img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
}
/* Fragrances Sec CSS */
.fragrances-banner a {
    padding-bottom: 20%;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    display: flex;
}

.fragrances-banner a img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100% !important;
    object-fit: cover;
}
.fragrances-title {
    position: absolute;
    bottom: 10px;
    text-align: center;
    background: rgb(255 255 255 / 72%);
    width: 100%;
    padding: 10px 10px;
}

.fragrances-title p {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 500;
}
/* Home Fragrance Product Sec CSS */
.home-fragrance-prd-sec .arrival-prd {
    border: solid 1px #F9BED7;
}
/* Accessories Sec CSS */
.accessories-cont {
    text-align: center;
    margin-top: 10px;
}
.accessories-cont h3 {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 5px;
}
.accessories-cont p {
    font-size: 14px;
    margin-bottom: 0;
}
/* Unisex Sec CSS */
.unisex-sec .arrival-bg {
    background: #DDEDF5;
}
.unisex-sec .arrival-bg .heading-type2 {
    background-image: url(../img/shape7.png);
    background-size: cover !important;
    background-repeat: no-repeat !important;
    padding: 5px 35px;
    background-position: center;
}
.heading-type1 a {
    color: #000;
    font-size: 24px;
    margin-bottom: 0;
    text-decoration: none;
}
.gift-card-img a {
    box-shadow: 0 0 15px rgb(0 0 0 / 10%) !important;
    border-radius: 15px;
    overflow: hidden;
    display: block;
}
.gift-card-img {
    padding: 10px 0;
}
/* Top Footer CSS */
.top-footer {
    background: #EAE9FE;
    padding: 15px 0;
}
.gift-card-img a {
    box-shadow: 0 0 15px rgb(0 0 0 / 10%) !important;
    border-radius: 15px;
    overflow: hidden;
    display: block;
}
.download-store {
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.download-store a {
    margin-right: 10px;
}
.download-wrap .heading-type2 {
    color: #000000;
}
.app-list {
    display: flex;
    justify-content: center;
    align-items: center;
}
.app-dtl-wrap {
    margin-top: 15px;
    background: #fff;
    padding: 15px 110px;
    border-radius: 15px;
    border: solid 1px #F9BED7;
    box-shadow: 0 3px 6px rgb(0 0 0 / 8%);
    margin-right: 40px;
}
.app-col {
    flex: 0 0 33.33%;
}
.app-box {
    text-align: center;
}
.app_title h3 {
    font-size: 15px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 0;
}
.app-icon {
    position: relative;
}

.app-icon:after {
    position: absolute;
    content: '';
    height: 30px;
    width: 1px;
    background: #707070;
    right: -2px;
    top: 5px;
}

.app-col:last-child .app-icon::after {
    display: none;
}
.rating_s_in {
    display: flex;
    align-items: flex-end;
}
.rating_thumb {
    flex: 0 0 100px;
    margin-right: 20px;
}
.rating_con {
    font-size: 20px;
    margin-right: 15px;
}
.rating_con h4 {
    font-size: 33px;
    font-weight: 700;
    margin-bottom: 5px;
    line-height: 1;
}
.rating_con p {
    margin-bottom: 0;
    line-height: 1.4;
}
.rating_cont {
    margin-top: 20px;
}

.rating_cont p {
    margin-bottom: 5px;
    font-size: 13px;
}

.rating_cont p.rate-user-name {
    font-weight: 500;
    margin-bottom: 0;
}
.rating_c_main .owl-dot {
    height: 4px;
    width: 15px;
    background: #8B87E1 !important;
    margin-right: 5px;
    border-radius: 10px;
    overflow: hidden;
}

.rating_c_main .owl-dot.active {
    width: 25px;
    background: #FC2779 !important;
}
.top-footer [class*="col-"]:first-child {
    position: relative;
}

.top-footer [class*="col-"]:first-child:after {
    content: '';
    position: absolute;
    background: #c5c5c5;
    height: 100%;
    width: 1px;
    right: 0;
    top: 0;
}

.rating_r_space {
    padding: 0 80px 0 40px;
}
.middle-footer {
    padding:20px 0 12px;
}
.footer-title h3 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
}
.footer-link ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}
.footer-link ul li {
    margin-bottom: 5px;
    line-height: 1.1;
}
.footer-link ul li a {
    color: #000;
    text-decoration: none;
    font-size: 14px;
    transition: all 0.5s;
}
.footer-link ul li a:hover {
    color: #f6157b;
    transition: all 0.5s;
}
.footer-title {
    margin-bottom: 10px;
}
.newsletter-wrap label {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    margin-right: 20px;
    flex: 0 0 230px;
    max-width: 230px;
}
.newsletter-wrap .input-group {
    align-items: center;
}
.form-wrap {
    display: flex;
    position: relative;
    flex: 0 0 calc(100% - 250px);
}
.form-wrap .form-control {
    padding-right: 160px;
}
.form-control {
    background: #fff !important;
    box-shadow: none;
    border: solid 1px #DCDCDC;
    padding: 8px 15px;
    min-height: 45px;
    border-radius: 7px !important;
}
.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000000;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #000000;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: #000000;
}
.subs-btn {
    position: absolute;
    right: 3.5px;
    top: 3.5px;
}
.subs-btn .btn_primary {
    min-height: 38px;
}
.subscribe-sec {
    border-top: solid 1px #BEBEBE;
    margin-top: 12px;
    padding-top: 12px;
}
.footer-link ul li:last-child {
    margin-bottom: 0;
}
.footer-social-sec {
    text-align: right;
}
.footer-social-sec ul {
    list-style: none;
    display: flex;
    margin-left: auto;
    margin-bottom: 0;
    justify-content: end;
}
.footer-social-sec ul li {
    margin-left: 10px;
}
.bottom-footer {
    background: #BCB9FC;
    padding: 5px 0;
}
.copyright-cont h4 {
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    margin-bottom: 0
}
.payment-img {
    margin-left: auto;
    text-align: right;
}
/* Custom css */
/* Reset Select */
.select select {
    appearance: none;
    outline: 0;
    border: 0;
    box-shadow: none;
    flex: 1;
    padding: 0 8px;
    color: #fff;
    background-color: #BCB9FC;
    cursor: pointer;
    text-align: center;
    min-height: 26px;
    align-items: center;
    display: flex;
    justify-content: center;
}
/* Remove IE arrow */
 .select select::-ms-expand {
     display: none;
}
/* Custom Select wrapper */
 .select {
     position: relative;
     display: flex;
     border-radius: 0.25em;
     overflow: hidden;
}
.country-slt {
    margin: 0 10px;
}
/* Product Listing Page CSS */
.accordion-header button.accordion-button {
    background: #FFE1EC;
    border-radius: 8px !important;
    border: 0 !important;
    box-shadow: none;
    color: #000;
    font-weight: 500;
    font-size: 14px;
    padding: 15px
}
.accordion-item {
    border: 0;
    background: #FFE1EC;
    border-radius: 8px !important;
}
.accordion-button:not(.collapsed)::after {
    filter: brightness(0);
}
.accordion-item {
    border: 0;
    background: #FFE1EC;
    border-radius: 8px !important;
    margin-bottom: 10px
}
.accordion-body {
    padding-top: 0;
}
.cart_left .accordion-body {
    background: #fff;
    border: solid 1px #fee1ec;
    padding-top: 15px;
    border-radius: 0 0 10px 10px;
}
.accordion-button:not(.collapsed)::after {
    filter: brightness(0);
}

/* Custom Radio Button  */
.custom_radio_b {
  display: flex;
  position: relative;
  padding-right: 32px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom_radio_b input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.custom_radio_b .checkmark {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: solid 2px #dcdcdc;
    background:#fff; 
}
.custom_radio_b input:checked ~ .checkmark {
    background-color: #FC2779;
    border: solid 2px #FC2779;
}
.custom_radio_b .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.custom_radio_b input:checked ~ .checkmark:after {
  display: block;
}
.custom_radio_b .checkmark:after {
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ffffff;
}
.product_wrap {
    display: flex;
}
.product_left_s {
    flex: 0 0 250px;
    max-width: 250px;
}
.accordion-body .accordion {
    margin-bottom: 0;
    padding: 0 5px
}
.accordion-body .accordion button.accordion-button {
    padding: 0;
    margin-bottom: 0;
    font-weight: 500
}
.accordion-body .accordion .accordion-body {
    padding: 10px 0 0;
    border-top: solid 1px #f91f7a;
    margin-top: 10px;
}
/* Custom Checkbox */
.custom_checkbox {
    display: flex;
    position: relative;
    padding-right: 20px;
    margin-bottom: 3px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 400;
    color: #000;
}
.custom_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.custom_checkbox .checkmark {
    position: absolute;
    top: 0;
    right: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border-radius: 5px;
    border: solid 1px #DCDCDC;
}
.custom_checkbox input:checked ~ .checkmark {
    background-color: #FC2779;
    border-color: #FC2779;
}
.custom_checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.custom_checkbox input:checked ~ .checkmark:after {
  display: block;
}
.custom_checkbox .checkmark:after {
    left: 6px;
    top: 3px;
    width: 4.5px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.accordion.filter_info .accordion-item {
    margin-bottom: 0;
    border-top: solid 1px #fff;
    border-radius: 0 !important;
}

div#accordionExample1 {
    background: #fee1ec;
    border-radius: 8px;
    overflow: hidden;
}

#accordionExample1 .accordion-item:first-child {
    border-top: 0;
}

#accordionExample1 .accordion-item .accordion-item {
    border-top: 0 !important;
    margin-bottom: 10px;
}
#accordionExample1 .accordion-item .accordion-item:last-child {
    margin-bottom: 0;
}
.filter_meta {
    text-align: right;
    margin-left: auto;
    margin-right: 5px;
    color: #000000;
    font-size: 13px;
    font-weight: 500
}
.product_right_s {
    flex: 0 0 calc(100% - 250px);
    padding-left: 20px;
    max-width: calc(100% - 250px);
}

.product_right_s .arrival-prd {
    border: 0;
    margin-bottom: 10px;
    position: relative;
    box-shadow: 0 0 10px hsl(0deg 0% 0% / 14%);
    width: 100%;
}

.row {
    margin: 0 -10px;
}

[class*="col-"] {
    padding: 0 10px;
}


/* Address Page CSS */
.inner-tit h3 {
    font-size: 18px;
    margin-bottom: 0;
}
.inner-tit {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}
.custom_radio_b .checkmark {
    left: 0;
}
.custom_radio_b {
    padding-right: 0;
    padding-left: 32px;
}
.address_tit {
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
}
.address_tag {
    font-size: 14px;
    margin-bottom: 0;
}
.address_inner_select {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #C5C5C5;
    padding-bottom: 15px;
    margin-bottom: 15px;
}
.address_inner_select a {
    color: #FC2779;
    text-decoration: none;
    font-size: 16px;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: 0;
    padding-right: 0 !important;
}
.bg-gray {
    background: #F2F2F2;
    padding: 25px;
}
.address_select {
    padding: 25px;
    background: #fff;
    border-radius: 10px;
}
.address_inner_select label.custom_radio_b {
    margin-bottom: 0;
    display: block;
}
.address_inner_select:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
.light-gray {
    padding: 25px;
    background: #DDEDF5;
}
.address_wrap .row {
    margin: 0px;
}
.add-address-tit {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
align-items: center;
}
.add-address-tit h4 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
}
.country_wrap {
    display: flex;
    margin-left: auto;
    align-items: center;
}
.address_wrap .address_form .row {
    margin: 0 -5px !important;
}
.country_name {
    display: flex;
    align-items: center;
}
.country_name  span {
    margin-left: 5px;
    font-size: 15px;
}
.country_label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    margin-right: 10px;
}
.form-group {
    margin-bottom: 20px;
}
textarea.form-control {
    min-height: 120px;
}
.custom_slt {
    display: flex;
}
.custom_slt .custom_radio_b {
    margin-right: 30px;
    padding-left: 25px;
    margin-bottom: 0;
    line-height: 1.3;
}   
.form-submit {
    display: flex;
    width: auto;
}
.form-submit .btn {
    width: auto;
}
.address-toggle input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
}
.address-toggle label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}
.address-toggle input:checked + label {
    background: #FC2779;
}
.address-toggle label {
    cursor: pointer;
    text-indent: -9999px;
    width: 45px;
    height: 24px;
    background: #FC2779;
    display: block;
    border-radius: 100px;
    position: relative;
    margin-bottom: 0 !important;
}
.address-toggle input:checked + label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
}
.address-toggle label:active:after {
    width: 20px;
}
.address-toggle {
    display: flex;
    align-items: center;
}
.address-toggle span {
    margin-left: 10px;
    font-size: 14px;

}
.locate-wrap {
    text-align: right;
}
.locate-wrap a {
    display: flex;
    justify-content: end;
    color: #f91f7a;
    text-decoration: none;
    align-items: center;
    font-size: 16px;
}
.locate-wrap a span.location-icon {
    margin-right: 10px;
    line-height: 1;
}


/* Cart Page CSS */
.cart_row {
    display: flex;
}
.cart_left {
    flex: 0 0 calc(100% - 350px);
    max-width: calc(100% -  350px);
}
.cart_title {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.clear_filter {
    margin-left: auto;
    font-size: 14px;
    color: #FC2779;
    font-weight: 500;
    text-decoration: none;
}
.cart_title h3 {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.cart_title h3 span {
    margin-right: 10px;
	margin-top: -1px;
}
.cart_address {
    background: #EAE9FE;
    padding: 15px 15px;
}
.cart_address_dtl {
    display: flex;
    padding: 15px 0;
}
.address_left_side {
    display: flex;
    align-items: center;
}
.address_right_side {
    margin-left: auto;
}
.address_ic {
    margin-right: 10px;
}
.text_bold {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 500;
}
.address-dtl span {
    font-size: 14px;
}
.edit_add {
    color: #FC2779;
    text-transform: uppercase;
    font-size: 15px;
}
.cart-address-wrap {
    background: #F2F2F2;
    padding: 15px;
}
.cart_address_bg {
    display: flex;
    background: #fff;
    padding: 15px;
    border-radius: 15px;
    align-items: center;
}
.cart_login_btn {
    margin-left: auto;
}
.cart_login p {
    margin-bottom: 0;
    font-size: 16px;
}
.cart-product {
    display: flex;
    border-bottom: solid 1px #C5C5C5;
    padding: 15px 0;
}
.cart-prd-img {
    flex: 0 0 90px;
    max-width: 90px;
    height: 90px;
}
.cart_product_list {
    padding: 0;
    margin-bottom: 10px;
}
.cart_prd_right {
    margin-left: auto;
    padding-left: 5px;
    text-align: right;
    flex: 0 0 120px
}
.item_total_price {
    display: flex;
    flex-direction: column;
}
.item_total_price h3 {
    color: #FC2779;
    font-size: 15px;
    margin-bottom: 0;
    font-weight: bold;
}
.item-remove {
    flex: 0 0 60px;
    text-align: right;
}
.prd-quantity span {
    color: #414141;
    font-size: 15px;
    font-weight: 500;
    margin-right: 10px;
}
.prd-quantityb select {
    border: 0 !important;
}
.prd-quantity {
    display: flex;
    align-items: center;
}
.prd-quantity select {
    border: 0;
    padding: 0;
    box-shadow: none !important;
}
.card-prd-cont p.discount-price {
    font-size: 14px !important;
    margin-top: 2px;
}
.cart_right {
    background: #DDEDF5;
    margin-left: 20px;
    flex: 0 0 330px;
    padding: 0;
}

.cart_right_title h3 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0
}
.cart_price ul {
    padding-left: 0;
    list-style: none;
}
.cart_price ul li {
    display: flex;
    margin-bottom: 10px;
}
.price_right {
    font-size: 13px;
    margin-left: auto;
    font-weight: 500;
}
.price_left {
    font-size: 13px;
}
.total_price span {
    font-weight: bold;
}
.gift-code .form-wrap {
    flex: 0 0 100% !important;
}

.gift-code .form-wrap .form-control {
    padding-right: 105px;
}
.gift-wrapping h3 {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 500;
}

.gift-wrapping h3 span {
    margin-right: 10px;
    margin-top:-3px
}


.select-gift-card .custom_radio_b .checkmark {
    height: auto;
    width: 55px;
    border-radius: 10px;
    overflow: hidden;
    border: 0 !important;
    background-color: transparent !important;
}
.select-gift-card .custom_radio_b .checkmark .gift-card-img {
    position: relative;
    padding-bottom: 82%;
    display: block;
    overflow: hidden;
}

.select-gift-card .custom_radio_b .checkmark .gift-card-img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.select-gift-card .custom_radio_b .checkmark::after {
    height: 30px;
    width: 30px;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
}

.select-gift-card .custom_radio_b .checkmark:before {
    content: "";
    position: absolute;
    left: calc(50% - 4px);
    top: calc(50% - 9px);
    width: 8px;
    height: 16px;
    border: solid #f6157b;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 111;
    display: none;
}
.custom_radio_b input:checked ~ .checkmark:before{
    display: block;
}
.select-gift-card {
    display: flex;
    margin-top: 15px;
    white-space: nowrap;
    overflow-x: auto;
}
.select-gift-card::-webkit-scrollbar {
    height: 5px;
}
.select-gift-card::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.select-gift-card::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 0px solid slategrey;
}

.select-gift-card label.custom_radio_b {
    flex: 0 0 60px;
    height: 55px;
    max-width: 60px;
    padding-left: 0;
}
.custom_radio_b input:checked ~ .checkmark span.gift-card-img:after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background: hsl(0deg 0% 0% / 32%);
    top: 0;
}
.add-btn {
    display: flex;
    margin-top: 15px;
}
.g-total {
    padding: 15px 15px 15px;
    border-top: solid 1px #ffffff;
    margin-top: 15px;
}
.g-total p {
    text-align: center;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 14px;
}
/* Checkout page css */
.cart_title h3 span.color-c {
    color: #f6157b;
}
.payment_select h4 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}
.payment_select label.custom_radio_b {
    display: flex;
    align-items: center;
    font-size: 14px;
}
.payment_select label.custom_radio_b span.checkmark {
    top: 2px;
}
.pay-option {
    margin-left: 10px;
}
form.address_form {
    margin-top: 20px;
}
span.pay-icon {
    margin-left: 5px;
}
.checkout-delivery-wrap {
    background: #F2F2F2;
    border-radius: 10px;
    padding: 15px 20px;
    display: flex;
    justify-content: center;
    max-width: 50%;
    flex: 0 0 50%;
}
.prd-dtl-bottom-cont {
    display: flex;
    gap: 15px;
    margin-top: 30px;
}
.checkout-col {
    flex: 0 0 50%;
    text-align: center;
    border-right: solid 1px #707070;
}
.checkout-box {
    display: flex;
    align-items: center;
    justify-content: center;
}
.checkout-col:last-child {
    border: 0;
}
.checkout-box span.c-icon {
    margin-right: 10px;
}
.checkout-box span {
    font-size: 15px;
}
.cart_right .cart-prd-img {
    flex: 0 0 60px;
    max-width: 60px;
    margin-right: 10px;
    background: #fff;
}

.item_total_price h3 {
    font-size: 18px;
}
.cart_right .cart_product_list {
    padding: 0 15px 15px;
    border-bottom: solid 1px #fff;
}
.cart_right_title {
    padding: 15px;
}

.cart_price {
    padding: 0 15px;
}
.cart_right .cart_product_list .cart-product:last-child {
    border-bottom: 0;
    padding-bottom: 0
}
.gift-code {
    padding: 0 15px;
}
/* Thank You Page CSS */
.thankyou_sec_in {
    max-width: 420px;
    margin: 30px auto;
    text-align: center;
}
.thankyou_sec_in h1 {
    font-size: 60px;
    margin: 20px 0 0;
    font-weight: bold;
    letter-spacing: -1px;
}
.thankyou_sec_in p {
    font-size: 24px;
    font-weight: 400;
    line-height: 1.2;
    margin: 20px 0;
}
.btn_thank {
    display: flex;
    justify-content: center;
}
.thankyou_sec_in img {
    max-width: 120px;
}


/* Popup CSS */
.btn-close {
    background-color: transparent !important;
    opacity: 1;
    padding: 0 !important;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-header {
    border-bottom: 0;
    padding-bottom: 5px;
}
.modal-header h5 {
    font-size: 18px;
    font-weight: 500;
}
@media (min-width: 576px){
.modal-dialog {
    max-width: 380px;
}
}
.modal-content {
    border-radius: 20px;
}
.modal-body .popup-cont {
    padding: 0;
}
.popup-cont h4 {
    font-size: 16px;
}
.popup-cont label.custom_checkbox {
    padding-right: 0;
    padding-left: 25px;
    margin-bottom: 10px;
    font-size: 16px;
}

.custom_checkbox_accords{
    padding: 3px;
    padding-left: 7px;
    border-radius: 25px;
}
.checkmark_accords{
    top: 4px !important;
    right: 5px !important;
}
.popup-cont label.custom_checkbox span.checkmark {
    left: 0;
    top: 2px;
}
.popup-cont label span.filter_meta {
    font-size: 15px;
    font-weight: bold;
    margin-right: 0;
    margin-left: auto;
}
.popup-cont label.custom_checkbox span.filter_meta.free-tag {
    color: #62C5F6;
}

.select_cls .filter_cont_s {
    padding-left: 30px;
    padding-bottom: 10px;
}
.already_cls {
    margin-top: 20px;
}

.popup-cont .already_cls p {
    margin-bottom: 0;
    font-size: 16px;
}

.already_cls p a {
    color: #f6157b;
    text-decoration: none;
}

.login-btn {
    display: flex;
}

.popup-cont label {
    font-size: 16px;
    margin-bottom: 10px;
    color: #000;
}
.popup-cont p {
    font-size: 16px;
}
.signin-cont {
    text-align: center;
    font-size: 16px;
}
.already_cls p + p {
    margin-top: 15px;
}
.accordion-description {
    background: #DDEDF5;
    padding: 15px;
    border-radius: 15px;
}
.accordion-description .nav-tabs {
    background: #FFFFFF;
    border: 0;
    border-radius: 0px;
    overflow: hidden;
}
.accordion-description .nav-tabs .nav-link.active {
    border: 0;
    color: #FC2779;
    border-bottom: solid 2px #FC2779 !important;
}
.accordion-description .nav-tabs .nav-link {
    border: 0 !important;
    font-size: 17px;
    color: #000;
    border-bottom: solid 2px #fff !important;
    margin-bottom: 0;
    border-radius: 0 !important;
}
.accordion-inner ul {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    border: solid 1px #cdcdcd;
    border-radius: 5px;
}
.accordion-inner {
    margin-top: 15px;
}
.accordion-inner ul li {
    margin-bottom: 0;
    display: flex;
    padding: 10px 15px;
    border-bottom: solid 1px #cdcdcd;
    flex: 0 0 50% !important;
    max-width: 50%;
    justify-content: space-between;
}
.accordion-inner ul li:last-child {
    border-bottom: 0;
}
.accordion-inner ul li span {
    min-width: 190px;
    font-size: 15px;
    font-weight: 500;
}
.accordion-inner ul li span.right-cont {
    font-weight: 400;
}
.prd-inner-bg {
    padding: 15px 10px;
    background: #F2F2F2;
    text-align: center;
    border-radius: 10px;
    flex: 0 0 50%;
    max-width: 50%;
}
.prd-inner-bg p {
    margin-bottom: 0;
    font-size: 15px;
}
.perfume_inner_title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
}
.perfume_inner_title h3 {
    font-size: 18px;
    margin-bottom: 0;
}
.show-votes span {
    margin-left: 0;
    margin-right: 10px;
}
.show-votes label {
    background: #EAE9FE;
}
.show-votes .address-toggle input:checked + label {
    background: #EAE9FE;
}
.show-votes .address-toggle label:after {
    background: #BCB9FC;
}
.prd-perfume-box {
    background: #fff;
    box-shadow: 0 0 20px rgb(125 125 125 / 25%);
    padding: 40px 20px;
    text-align: center;
    border-radius: 20px;
}

.prd-perfume-img {
    position: relative;
    padding-bottom: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prd-perfume-img img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    width: auto;
}

.prd-perfume-box h4 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 15px;
}
.rating_title {
    font-size: 84px;
    font-weight: 500;
    margin-right: 10px;
    line-height: 1;
}


.rating-percents {
    display: flex;
    flex-direction: column;
    grid-row-gap: 0;
}
.rating-percent {
    display: flex;
    align-items: center;
}
.rating-no {
    font-size: 16px;
    font-weight: 500;
    color: #414141;
    display: flex;
    align-items: center;
    padding-right: 15px;
}
.add-btn-grp a {
    text-decoration: none;
}

.rating-progress {
    width: 100%;
}

.rating-progress div {
    margin: 0 !important;
    height: 7px !important;
    background: #DDEDF5 !important;
}

.rating-no img {
    max-width: 12px;
    margin-left: 3px;
    margin-top: -2px;
}
.rating-progress div div {
    background: #62C5F6 !important;
    font-size: 0;
}
 /* .rating-progress {
     margin: 0 0.8rem;
     background-color: #DDEDF5;
     border-radius: 1.8rem;
     width: 220px;
     height: 7px;
     position: relative;
}
 .rating-progress::before {
     position: absolute;
     content: '';
     top: 0;
     left: 0;
     height: 100%;
     background-color: #62C5F6;
     border-radius: 1.8rem;
}
 .rating-percent:nth-child(1) .rating-progress::before {
     width: 84%;
}
 .rating-percent:nth-child(2) .rating-progress::before {
     width: calc(2* 30%);
}
 .rating-percent:nth-child(3) .rating-progress::before {
     width: calc(2* 20%);
}
 .rating-percent:nth-child(4) .rating-progress::before {
     width: calc(2* 15%);
}
 .rating-percent:nth-child(5) .rating-progress::before {
     width: calc(2* 8%);
} */
.rating-sec {
    display: flex;
}
.rating-right {
    flex: 0 0 390px;
    padding: 0 55px;
    border-left: solid 1px #002137;
}
.rating-left {
    padding: 0 55px;
}
.reviiew-dtl {
    display: flex;
    padding: 15px 0;
    border-top: solid 1px #CBCBCB;
    border-bottom: 0;
}
.review-inner:last-child .reviiew-dtl {
        border-bottom: solid 1px #CBCBCB;
}
.review-left {
    display: flex;
    flex: 0 0 50px;
    max-width: 50px;
    align-items: start;
    height: unset !important;
    margin-right: 10px;
}

.review-dtl-bg {
    background: #62C5F6;
    height: auto;
    width: 100%;
    text-align: center;
    color: #fff;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.review-dtl-bg span img {
    filter: brightness(0) invert(1);
    margin-left: 3px;
    margin-top: -2px;
}
.review-right p {
    margin-bottom: 0px;
    color: #000000;
    font-size: 15px;
    font-weight: 500;
}
.review-dtl-bg span img {
    margin-right: 3px;
    margin-left: 0;
}
.review-right span {
    color: #707070;
    font-size: 14px
}
.product-slide-main {
    background: #fff;
    box-shadow: 0 0 15px hsl(0deg 0% 0% / 16%);
    padding: 20px;
    border-radius: 15px;
}
.slide-main {
    text-align: center;
}
.prd-thumb ul {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
    display: flex;
    margin-top: 10px;
}
.prd-thumb ul li {
    height: 80px;
    width: 80px;
    background: #fff;
    border-radius: 10px;
    border: solid 1px #ddd;
    text-align: center;
    display: flex;
    align-items: center;
    margin-right: 10px;
    justify-content: center;
}
.prd-thumb ul li:last-child{
    margin-right: 0
}


.prd-title {
    display: flex;
    justify-content: space-between;
}

.prd-title h2 {
    font-size: 20px;
    margin-bottom: 0;
}

.prd-cont-sec {
    display: flex;
}

.main-prd-left {
    flex: 0 0 50%;
    padding-right: 15px;
}

.product-inner-dtl ul {
    display: flex;
    padding-left: 0;
}

.product-inner-dtl ul li {
    padding: 0 25px;
    list-style: none;
    border-right: solid 1px #B8B8B8;
}

.prd-review {
    display: flex;
    align-items: center;
}

.prd-review span:first-child {
    margin-right: 5px;
    margin-top: -3px;
}

.prd-review span {
    font-size: 15px;
    font-weight: bold;
    color: #707070;
}

.product-inner-dtl ul li span {font-size: 15px;}

.product-inner-dtl ul li:last-child {border-right: 0;padding-right: 0;}

.product-inner-dtl ul li:first-child {
    padding-left: 0;
}

.prd-dtl-cont {
    padding-left: 20px;
}

.prd-price-wrap {
    display: flex;
    align-items: center;
    margin:10px 0 20px;
}

.prd-price-wrap .arrival-price {
    display: flex;
}
.prd-brand .brand_img span img {
    max-width: 50px;
}

.prd-brand {
    margin-left: 15px;
}

.prd-price-wrap .arrival-price p {
    font-size: 17px;
}

.prd-price-wrap .arrival-price p.discount-price {
    font-size: 15px;
    margin-left: 10px;
    margin-top: 2px;
}

.q_btn_group {
    display: flex;
    width: auto;
    border: solid 1px #DCDCDC;
    padding: 5px 5px;
    border-radius: 60px;
}

.quantity_sec {
    display: flex;
    align-items: center;
}

.q_btn_group input {
    border: 0;
    text-align: center;
    outline: 0 !important;
}

.q_btn_group button {
    background: #f91f7a;
    border: 0;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #fff;
    border-radius: 50%;
}

.quantity_sec h4 {
    font-size: 16px;
    color: #414141;
    /* flex: 0 0 120px; */
    margin-right: 15px;
}
.prd-size {
    padding: 10px 0;
}

.prd-size h4 {
    font-size: 16px;
}

.prd-size ul {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.prd-size ul li {
    padding: 0px 5px;
}

.prd-size ul a {
    border: solid 1px #DCDCDC;
    display: block;
    padding: 5px 20px;
    border-radius: 40px;
    text-decoration: none;
    color: #000;
    font-size: 14px;
}

.prd-size ul a.active {
    border-color: #f6157b;
}
.main-prd-right {
    width: 100%;
}

.perfume-acords h4 {
    font-size: 16px;
    margin-bottom: 10px
}

.progress-wrap div span {
    font-size: 13px;
}

.woody-bg {
    background: #774414;
    border-radius: 3px;
    color: #fff;
    padding: 0 10px;
    max-width: 95%;
}

.aromatic-bg {
    background: #56AF9B;
    max-width: 90%;
    color: #fff;
}

.progress-wrap div {
    border-radius: 3px;
    padding: 0 10px;
    border-bottom: solid 1px #fff;
    overflow: hidden;
    color: #fff;
}

.floral-bg {
    background: #FF85A8;
    max-width: 85%;
}

.rose-bg {
    background: #FE4190;
    max-width: 80%;
}

.lavender-bg {
    background: #EDDAF1;
    max-width: 75%;
    color: #000 !important;
}

.musky-bg {
    background: #EEE4F0;
    max-width: 70%;
    color: #000 !important;
}

.citrus-bg {
    background: #FBFF8D;
    max-width: 65%;
    color: #000 !important;
}

.powdery-bg {
    background: #F3E8DD;
    color: #000 !important;
    max-width: 60%;
}

.balsamic-bg {
    background: #CAAF94;
    max-width: 55%;
    color: #000 !important;
}

.vanilla-bg {
    background: #FFFED7;
    color: #000 !important;
    max-width: 50%;
}

.prd_size_label {
    display: flex;
    flex-wrap: wrap;    
}
.prd_size_label label.custom_radio_b {
    height: 100%;
    padding: 0;
    align-items: center;
}
.prd_size_label label.custom_radio_b span.checkmark {
    min-width: 70px;
    padding: 0  15px;
    border-radius: 60px;
    min-height: 35px;
    background: transparent !important;
    position: unset;
    display: flex;
    align-items: center;
    margin-right: 10px;
    width: 100%;
}
.prd_size_label .custom_radio_b input:checked ~ .checkmark:after {
    display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



.category-sec .col_1 {
    flex: 0 0 calc(100% / 10);
    max-width: calc(100% / 10);
    margin-bottom: 15px
}





.slideshow-items {
  width: 500px;
    margin: 0 auto;
}
.slideshow-thumbnails {
    width: 60px !important;
    padding: 4px !important;
    border: solid 1px #ddd !important;
    margin: 10px 2px 0;
    border-radius: 5px;
    height: 60px;
    object-fit: cover;
}
#slideshow-items-container { display: inline-block; position: relative; }

#lens { background-color: rgba( 233, 233, 233, 0.4 ) }
#lens, #result { position: absolute; display: none; z-index: 1; }
.slideshow-items { display: none; }
.slideshow-items.active { display: block; }
.slideshow-thumbnails { opacity: 0.5; }
.slideshow-thumbnails.active { opacity: 1; }
#lens, .slideshow-items, .slideshow-thumbnails, #result { border: solid var(--light-grey-2) 1px; }


.others-option  .offer a {
    display: flex;
    text-decoration: none;
    align-items: center;
    animation-name: blinker;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes blinker {  
 0% { opacity: 1.0; }
 25% { opacity: 0.50; }
 50% { opacity: 0.70; }
 75% { opacity: 0.85; }
 100% { opacity: 1.0; }
}
.newsletter-wrap .form-group {
    margin-bottom: 0;
}
.item a {
    color: #000;
}
.btn_cross img {
    max-width: 15px;
}
.btn_cross {
    border: 0;
    background: transparent;
    padding: 0;
}
.perfume_inner_title h4 {
    font-size: 18px;
    margin-bottom: 0
}
.prd-title {
    margin-bottom: 10px;
}
span.rating-ic {
    margin-top: -10px;
    display: inline-flex;
}
.share-option {
    flex: 0 0 40px;
    max-width: 40px;
    text-align: right;
}
.banner-sec .container .banner-img {
    border-radius: 15px;
    overflow: hidden;
}

/* My account Page CSS */
.filter_mobile_s {
    display: none;
}
.filter_cross {
    display: none;
}
.my-account-inner {
    display: flex;
}
.myaccount_l {
    flex: 0 0 250px;
    max-width: 250px;
}
.sidebar-link {
    background: #F2F2F2;
    border-radius: 15px;
    padding: 15px;
}
.sidebar-link ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.sidebar-link ul li {
    width: 100%;
    padding: 8px 10px;
}
.sidebar-link ul li a {
    text-decoration: none;
    color: #000;
    font-size: 16px;
    display: flex;
    align-items: center;
}
.pro-icon {
    margin-right: 10px;
    flex: 0 0 25px;
    max-width: 25px;
    margin-top: -3px;
}
.sidebar-link ul li a.active {
    color: #f91f7a;
}
.pro-icon img {
    filter: brightness(0) invert(0);
}
.sidebar-link ul li a.active .pro-icon img {
    filter: unset;
}
.myaccount_r {
    width: 100%;
    padding-left: 20px;
}
.myaccount_bg {
    width: 100%;
    background: #DDEDF5;
    padding: 15px;
    border-radius: 15px;
}
.myaccount_dtl {
    display: flex;
    height: 100%;
    align-items: center;
}
.profile_bg {
    background: #fff;
    padding: 50px;
    flex: 0 0 260px;
    margin-right: 20px;
    text-align: center;
    border-radius: 15px;
    height: 100%;
    max-width: 260px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.user-profile-dtl h3 {
    font-size: 20px;
    margin-bottom: 15px;
}
.user-mail {
    margin-bottom: 15px;
}
.user-mail span {
    display: block;
    font-size: 14px;
    color: #858282;
}

.user-mail a {
    color: #000;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
}

.user-contact-dtl {
    display: flex;
    margin: 0 -15px;
}

.user-contact-dtl span {
    display: block;
    font-size: 14px;
    color: #858282;
}

.user-contact-dtl  div {
    padding: 0 15px;
    border-right: solid 1px #858282;
}

.user-contact-dtl a {
    color: #000;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
}

.edit-profile {
    display: flex;
    margin-top: 15px;
}
.user-contact-dtl div:last-child {
    border-right: 0;
}

.added-address h3.heading-type2 {
    color: #000;
}
.added-address .address_select {
    padding: 0;
    margin-top: 15px;
}
.added-address .address_select .address_inner_select {
    border: solid 1px #D8D8D8;
    padding: 20px;
    border-radius: 10px;
}
.add-new-address {
    margin-top: 15px;
}
.add-new-address .heading-type2 {
    color: #000;
    font-size: 20px;
}
.add-new-address .locate-wrap a {
    justify-content: start;
}

.add-new-address .locate-wrap {
    text-align: left;
    margin-top: 15px;
}
.profile_img {
    border-radius: 50%;
    overflow: hidden;
    height: 150px;
    width: 150px;
}
.profile_img img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}
.edit-account-page .myaccount_dtl {
    align-items: center;
}
.edit-account-page .edit-profile {
    margin-left: 10px;
    display: block;
    margin-top: 0;
}
.edit-account-page .edit-profile a {
    color: #f91f7a;
    text-decoration: none;
}
.edit-account-page .myaccount_r .heading-type2 {
    color: #000;
    margin-bottom: 15px;
}
.profile-edit-bor {
    flex: 0 0 115px;
    border: solid 3px #BCB9FC;
    border-radius: 50%;
    padding: 5px;
}
.file_input {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.file_input input[type=file] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
}
.file_input span {
    width: 100%;
    padding: 5px 10px;
    font-size: 18px;
    height: 100%;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f91f7a;
    border-radius: 15px;
}
.wallet_inner {
    background: #eae9fd;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-radius: 15px;
    align-items: center;
}
.wallet_bg {
    width: 100%;
}
.wallet_bal {
    padding: 0 15px;
    border-right: solid 1px #999999;
}
.wallet_bal:last-child {
    border-right: 0;
}
.wallet_cont span {
    font-size: 14px;
}
.wallet_cont h5 {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 10px;
}
.wallet_amount {
    display: flex;
    margin: 0 -15px;
}
.ballance_right {
    margin-left: auto;
    flex: 0 0 95px;
    text-align: right;
    padding-left: 5px;
}
.wallet_icon {
    flex: 0 0 20px;
}
.wallet_bal span.font-bold {
    font-size: 15px;
    font-weight: 500;
}
.wallet_bal:last-child {
    border-right: 0;
}
.wallet_feat .app-dtl-wrap {
    margin-right: 0;
    background: #f2f2f2;
    border: 0;
    margin-top: 10px;
}
.wallet_feat .heading-type2 {
    color: #000;
}
.wallet_list {
    display: flex;
    padding: 15px 0;
    border-bottom: solid 1px #C5C5C5;
}
.ballance_right {
    margin-left: auto;
}
.wallet_cont h5 {
    font-size: 18px;
    margin: 5px 0;
    font-weight: 500;
}
.wallet_list .wallet_cont {
    margin-left: 10px;
}
.transaction_wrap {
    margin-top: 20px;
}
.wallet_cont p {
    margin-bottom: 0;
    font-size: 15px;
    color: #707070;
}
.date_wrap p {
    font-size: 16px;
    margin-bottom: 0;
    color: #707070;
}
.wallet_list:last-child {
    border-bottom: 0;
    padding-bottom: 0
}
.transaction_wrap .heading-type2 {
    color: #000;
    border-bottom: solid 1px #C5C5C5;
    padding-bottom: 10px;
}
.nav-item.mobile_menu {
    display: none;
}
.notify_me_btn {
    background: #bcb9fa;
    color: #000 !important;
}
.notify_btn {
    background: #f9bed7;
    border-color: #f9bed7;
}




.sale-prd a {
    border-radius: 15px;
    overflow: hidden;
    display: block;
}
.gift-banner a {
    border-radius: 15px;
    display: block;
    overflow: hidden;
}
.fragrances-img a {
    border-radius: 15px;
    overflow: hidden;
    display: block;
}
.accessories-img a {
    display: block;
    border-radius: 15px;
    overflow: hidden;
}
.cate-banner a {
    border-radius: 15px;
    overflow: hidden;
    display: block;
}
.prd-brand .brand-img {
    position: unset;
    height: 70px;
    width: 70px;
    border: solid 2px #bcb9fa;
    border-radius: 50%;
    padding: 10px;
display: flex;
    align-items: center;
    justify-content: center;
}
.prd-brand .brand-img img {
    position: unset;
    transform: unset !important;
}
.download-store a:last-child {
    margin-right: 0;
}

.desktop_menu_none {
    display: none;
}
.mobile_menu_ic {
    display: none;
}
.cart-prd-img img {
    max-height: 90px;
}
.cart-prd-img {
    border: solid 1px #c5c5c5;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.myaccount_r .heading-type2 {
    color: #000;
    margin-bottom: 5px;
}



/* My Card CSS */
.card-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0
}
.mycard-cls {
    background: #EAE9FE;
    padding: 15px;
    border-radius: 20px;
      margin-bottom: 15px;
}
.card_option .custom_radio_b .checkmark {
    height: 25px;
    width: 25px;
    border: 0 !important;
}
.card_option .custom_radio_b .checkmark:after {
    top: 7px;
    left: 7.5px;
}
.remove-card:last-child {
    margin-right: 0;
}
.card_option .custom_radio_b input {
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
}
.card-left h5 {
    font-size: 14px;
    margin-bottom: 0;
    color: #000;
    font-weight: 400;
}
.card-number h2 {
    color: #000;
    font-size: 22px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 0;
}
.card_valid {
    display: flex;
    margin: 10px 0;
}
.card_left_valid h5 {
    font-size: 11px;
    color: #000;
    margin-bottom: 0;
    font-weight: 500;
}
.card_left_valid h3 {
    font-size: 17px;
    font-weight: 400;
    color: #000;
    margin-bottom: 0;
}
.cvv-no {
    width: 60px;
    height: 38px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FC2779;
    font-size: 18px;
    margin-left: 15px;
}
.card_type_name {
    display: flex;
    align-items: center;
}
.card-type {
    margin-left: auto;
}
.card-holder-name h4 {
    color: #000;
    margin-bottom: 0;
    font-size: 12px;
}
.card_option .custom_radio_b {
    flex: 0 0 25px;
    margin-bottom: 0;
    padding-left: 0 !important;
    margin-right: 10px;
}
.card-right {
    flex: 0 0 25px;
}
.my-card-sec {
    display: flex;
}
.card_left_wrap {
    flex: 0 0 calc(100% - 410px);
    padding-right: 20px;
}
.make_default {
    background: #eae9fd;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin: 0px 0 15px;
}
.make_default .address-toggle input:checked + label {
    background: #BCB9FC;
}
.make_default .address-toggle label{
    background: #BCB9FC;
}
.card_right_wrap {
    flex: 0 0 400px;
    padding-left: 20px;
    border-left: solid 1px #ddd;
}
.wishlist_item .arrival-prd {
    margin-bottom: 10px;
    position: relative;
    box-shadow: none;
    border: solid 1px #F9BED7;
}
.prd_remove {
    position: absolute;
    z-index: 11;
    right: 15px;
    top: 10px;
}
.sold_rate {
    display: flex;
    align-items: center;
}
.sold_dtl p {
    margin-bottom: 0;
    font-size: 13px;
}
.sold_dtl {
    margin-left: auto;
}
span.ratting-icon {
    line-height: 1;
    margin-top: -3px;
}

/* Notification Page CSS */
.noti_space {
    border-radius: 15px;
    margin-top: 15px;
    background: #F2FBFF;
    padding: 15px;
}
.noti_space p {
    font-size: 15px;
    margin-bottom: 0;
}
.time_remove {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.noti_time span {
    font-size: 13px;
    color: #393939;
    display: block;
}
.noti_remove span a {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.unread_notification {
    background: #D8F3FF;
}
.notification-drop .dropdown-toggle::after {
    display: none;
}

.notification-drop {
    position: relative;
}
.notification-drop ul.dropdown-menu {
    inset: unset !important;
    min-width: 190px;
    left: unset !important;
    right: 0 !important;
    transition: unset !important;
    padding: 10px 0;
    border: 0;
    border-radius: 10px !important;
    box-shadow: 0 0 16px #0000002e;
    transform: unset !important;
}
.notification_title {
    display: flex;
    align-items: center;
}
.notification_title .heading-type2 {
    margin-bottom: 0;
}

/* Order Page CSS */
.order-page .accordion-description {
    background: transparent;
    padding: 0;
}
.order-body .cart-prd-img img {
    max-width: 50px;
}
.order-body .cart-prd-img {
    max-width: 75px;
    flex: 0 0 75px;
    height: 75px;
}
.order-body .cart-product {
    border: 0;
    padding: 12px;
}
.order_dtl {
    display: flex;
    align-items: self-start;
}
.order-id {
    margin-bottom: 2px;
    font-size: 22px;
}
.date_time {
    font-size: 18px;
    color: #414141;
    margin: 5px 0 0;
}
.order_payment {
    margin-bottom: 0;
    font-size: 17px;
    color: #414141;
    font-weight: 500;
}
.order_right {
    margin-left: auto;
}
.order-btn {
    font-size: 13px;
    border: solid 1px #43CB50;
    padding: 3px 15px;
    color: #43CB50;
    background: #D9FFDD;
    border-radius: 6px;
    display: flex;
    font-weight: 500;
    text-align: center;
    justify-content: center;
    margin-left: auto;
}
.order_wrap {
    border: 0;
    border-radius: 10px;
    margin-bottom: 15px;
    background: #eae9fd;
    padding: 15px;
}
.order-body {
    background: #fff;
    margin-bottom: 10px;
    border: 0 !important;
    border-radius: 10px;
}
.order-body:last-child {
    margin-bottom: 0;
}
.order-actions {
    margin-top: 10px;
}
.order_wrap .cart_prd_right {
    display: flex;
    justify-content: end;
}
.order_wrap:last-child {
    margin-bottom: 0;
}
.new {
    background: #F3F3F3;
    border-color: #000;
    color: #000;
}
.cancel {
    background: #FFE6EF;
    border-color: #FC2779;
    color: #FC2779;
}
.order-page-dtl .order_wrap {
    border: 0;
    margin-top: 10px;
}
.order-page-dtl .order-id {
    font-size: 18px;
    margin-bottom: 5px;
}
.order-page-dtl .date_time {
    margin-bottom: 5px;
    font-size: 16px;
}
.order-page-dtl .order_payment {
    font-size: 16px;
}
.order-address-bg {
    display: flex;
    background: #EAE9FE;
    padding: 15px;
    border-radius: 15px;
    align-items: center;
}
.order-address-page {
    margin-top: 15px;
}
.order-address-dtl h4 {
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: 500;
}
.order-address-dtl {
    margin-left: 15px;
}
.order-address-dtl p {
    margin-bottom: 0;
    font-size: 14px;
}
.order-status {
    margin: 15px 0;
}


.prd-cont-sec button.btn.btn_primary.me-2.btn.btn-primary {
    width: auto;
}



/*tracking css*/
    .completed .timing:before {
        background: #43CB50;
    }
    .timing:before {
        content: '';
        position: absolute;
        height: 28px;
        width: 28px;
        background: #EAE9FE;
        right: -8px;
        border-radius: 50%;
        z-index: 1;
        top: -1px;
    }
    .activeted .timing:before {
        background: #43CB50;
    }
    .timing:after {
        content: '';
        position: absolute;
        background:#BCB9FC;
        width: 8px;
        height: 100%;
        right: 2px;
        top: 0;
    }
    .completed .timing:after {
        background: #43CB50;
    }
    .tracking-main-class .timing {
        position: relative;
        padding-left: 20px
    }
    .tracking-main-class p.font18m {
        font-size: 14px;
        color: #202020;
        margin-bottom: 0;
        display: flex;
        align-items: center;
    }
    .tracking-main-class .store-dtl {
        width: 100%;
        padding-left: 25px;
        padding-bottom: 25px;
        min-height: 65px;
        padding-top: 5px
    }    
    .tracking-main-class .store-dtl h4 {
        font-size: 16px;
        margin-bottom: 0;
        text-transform: capitalize;
    } 
    .tracking-main-class {
        margin-top: 15px;
    }
    .track-dtl:last-child .timing:after {
        display: none;
    }
    .time-cls {
        padding-top: 2px;
        margin-left: 10px;
    }
    .order-status .order-actions {
        padding: 0;
        margin-top: 0;
    }
    .order_download {
        align-items: center;
    }
    .order_download .locate-wrap {
        margin-left: 20px;
    }
    .order_download .locate-wrap a {
        color: #000;
    }
    .order_right .order-status {
        margin: 0;
        display: flex;
        flex-direction: column;
    }
    .login_after.dropdown a {
        padding: 0;
    }
    .login_after.dropdown a.dropdown-toggle::after {
        display: none;
    }
    .login_after .dropdown-menu {
        display: none;
    }
    .login_after  .dropdown-menu.show {
        display: block;
        transform: unset !important;
        inset: inherit !important;
        top: 48px !important;
        right: 0 !important;
        padding: 0;
        background: #fff;
        box-shadow: 0 0 10px hsl(0deg 0% 0% / 23%);
        border: 0;
    }
    .login_after .dropdown-menu li {
        margin: 0;
    }
    .login_after .dropdown-menu li .dropdown-item {
        padding: 10px 15px;
        border-bottom: solid 1px #f1f1f1;
        display: flex;
        align-items: center;
        background: transparent !important;
        color:#000 !important;
    }
    .login_after .dropdown-menu li .dropdown-item span {
        margin-right: 10px;
    }
    .desktop-menu {
        max-width: 90px;
        overflow: hidden;
        display: block;
        font-size: 15px;
        color: #000000;
        margin-left: 10px;
        font-weight: 500;
        text-overflow: ellipsis;
    }
    .login_after.dropdown a {
        text-decoration: none;
        display: flex;
        align-items: center;
    }
    .login_after.dropdown .btn {
        padding: 0 !important;
        border: 0 !important;
        outline: 0 !important;
    }
    .login_after .dropdown-menu li .dropdown-item.active {
        color: #f91f7a !important;
    }
    .login_after .dropdown-menu li .dropdown-item.active  svg path{
        fill: #f91f7a !important;
    }
    .order-id a {
        text-decoration: none;
        color:#000
    }
    .login_after .dropdown-menu li .dropdown-item.active svg  circle {
        stroke: #f91f7a !important;
    }
    .add-btn-grp .btn_primary {
        min-width: 125px;
    }
    .cart_title span.bag_cont {
        margin-right: 2px;
    }
    .bag_count {
        display: none;
    }
    /* .mike_sec{display: none;} */

    .not_found_page {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: calc(100vh - 655px);
    }
    .notfound-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px 0;
    }
    .notfound-inner h1 {
        font-size: 60px;
        color: #ffb1cf;
        font-weight: 800;
        margin-bottom: 0;
    }
    .notfound-inner h3 {
        font-size: 32px;
        font-weight: 900;
        margin: 15px 0;
    }
    .notfound-inner p {
        font-size: 16px;
        text-align: center;
        font-weight: 700;
    }

    .btn_primary:hover {
        background-color: #b30a59 !important;
        border-color: #b30a59 !important;
        outline: 0 !important;
        box-shadow: none !important;
    }

    .hideCl{
        display: none !important;
    }

    .opt_screen input {
        width: 45px !important;
        margin: 0 10px;
        height: 40px;
        color: #000;
        border-radius: 7px;
        border: solid 1px #999;
        box-shadow: none !important;
        outline: 0 !important;
    }
    .prd-img a {
        position: relative;
        border-radius: 15px;
        padding-bottom: 100%;
        overflow: hidden;
    }
    .prd-img a img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        max-width: 100%;
        max-height: unset;
        width: 100% !important;
        height: unset;
    }
    .offer-banenr-sec .banner-img {
        overflow: hidden;
        position: relative;
        padding-bottom: 18%;
        border-radius: 20px !important;
    }
    
    .offer-banenr-sec .banner-img picture img {
        object-fit: cover;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        max-width: 100%;
        max-height: 100%;
    }
    .category-sec .banner-img {
        height: 110px;
        width: 110px;
        border-radius: 50%;
        overflow: hidden;
        border: solid 2px #c6c4fb;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        margin: 0 auto;
    }
    .category-sec .banner-img img {
        max-height: 100%;
        max-width: 100%;
        width: auto !important;
        margin: 0 auto;
    }
    .gift-card-sec .banner-img {
        border: solid 1px #dcf3f4;
        border-radius: 15px;
    }
    .rating_c_main .owl-theme .owl-nav.disabled+.owl-dots {
        margin-top:0;
        text-align: left;
    }
    .login_after.dropdown .btn.dropdown-toggle::after {
        display: none;
    }
    .search_dropdown_cls {
        position: absolute;
        z-index: 100;
        border: none;
        padding: 0;
        top: 45px;
        max-height: 70vh;
        overflow-x: auto;
        width: 70%;
        box-shadow: 0 0 20px rgb(0 0 0 / 85%);
        width: 100%;
        left: 0;
        background: white;
        border-radius: 3px;
    }
    .search_dropdown_cls::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }
    .search_dropdown_cls::-webkit-scrollbar {
        width: 7px;
        background-color: #F5F5F5;
    }
    .search_dropdown_cls::-webkit-scrollbar-thumb {
        background-color: #000000;
        border: 2px solid #000000;
    }
    .search_dropdown_cls li {
        display: flex;
        align-items: center;
        border-bottom: solid 1px #cbc8c8;
        padding: 10px 0;
    }
    .search_dropdown_cls li img {
        flex: 0 0 35px;
        max-width: 35px;
    }
    .search_dropdown_cls li  a {
        color: #000;
        text-decoration: none;
        flex: 0 0 calc(100% - 45px);
        margin-left: 10px;
    }
    .search_dropdown_cls li:last-child {
        border-bottom: 0;
    }
    .react-tel-input .form-control {
        width: 100% !important;
    }
    hr {
        margin: 0.5rem 0;
        color: inherit;
        border: 0;
        border-top: 1px solid;
        opacity: .25;
    }

    .shareBtn {
        background: transparent;
        border: none;
        font-size: 20px;
    }
    .shareBtn:hover{
        background: transparent;
        border: none;
        font-size: 20px;
    }
    .cart_left .prd-quantity .q_btn_group button {
        height: 25px;
        width: 25px;
        font-size: 20px;
    }
    .newsletter-wrap .mb-3 {
        margin: 0 !important;
    }    
    .cart-product-new .card-prd-cont {
        flex-basis: calc(100% - 150px);
        max-width: calc(100% - 150px);
    }
    .cart-product-new  .cart_prd_right {
        flex: 0 0 110px;
        max-width: 110px;
    }
    .form-wrap div {
        width: 100%;
    }
    .form-wrap .subs-btn {
        width: auto;
    }
    .app-icon img {
        max-width: 30px;
    }
    .head-right ul li a.active g {
        stroke: #f91f7a;
    }
    .product-slide-main .zoom_sld_main div img {
        display: flex;
        height: auto!important;
        justify-content: center;
        margin: 0 auto;
        max-width: 380px;
        width: 100%!important;
        max-height: 380px;
        object-fit: cover;
    }
    .rangeslider-horizontal .rangeslider__fill {
        background-color: #f6157b !important;
    }
    .rangeslider .rangeslider__handle {
        box-shadow: 0 1px 3px rgb(0 0 0 / 8%), 0 -1px 3px rgb(0 0 0 / 0%) !important;
    }
    .rangeslider-horizontal .rangeslider__handle:after {
        width: 10px !important;
        height: 10px !important;
        top: 9px !important;
        left: 9px !important;
        background-color: #f6157b !important;
        box-shadow: 0 1px 3px rgb(246 21 123) inset, 0 -1px 3px rgb(246 21 123) inset !important;
    }
    .added-address {
        margin-top: 20px;
    }
    .bottom-footer .row {
        align-items: center;
    }
    .accordion-body .filter_cont_s .custom_checkbox.custom_checkbox_accords {
        border-radius: 7px;
    }
    
    .accordion-body .filter_cont_s .custom_checkbox.custom_checkbox_accords input:checked ~ .checkmark {
        background-color: #fff;
        border-color: #fff;
    }

    .accordion-body .filter_cont_s .custom_checkbox_accords.custom_checkbox .checkmark:after {
        border-color: #f91f7a;
    }
    .addAddress_cls {
        margin-top: 10px;
        display: inline-block;
    }
    .card-prd-cont .prd-quantity .q_btn_group {
        padding: 3px;
    }
    .cart_left .prd-quantity .q_btn_group input {
        height: 25px;
        width: 75px;
    }
    .g-total .btn_primary {
        width: 100%;
    }
    .payment_select {
        margin-bottom: 15px;
    }
    .cart_right .form-wrap {
        margin: 15px 15px 0;
    } 
    .no_image img {
        max-width: 220px;
    }
    .accordion-description .nav-tabs li {
        padding: 0;
        border: 0 !important;
        font-size: 17px;
        color: #000;
        border-bottom: solid 2px #fff !important;
        margin-bottom: 0;
        border-radius: 0 !important;
        bottom: 0 !important;
    }
    .react-tabs li.react-tabs__tab--selected {
        border: 0;
        color: #FC2779;
        border-bottom: solid 2px #FC2779 !important;
    }
    .accordion-description .nav-tabs li.react-tabs__tab button {
        border: 0 !important;
    }
    .react-tabs li.react-tabs__tab--selected:after {
        display: none;
    }
    .order-body .cart-prd-img img {
        height: 100%;
        object-fit: cover;
        width: 100%;
        max-width: 100% !important;
    }
    .order_wrap .order-body + .order-body {
        border-top: solid 1px #c5c5c5;
    }
    .order_right .order-status .btn_primary {
        margin-top: 10px;
        padding: 0 15px;
    } 
    .myaccount_r.myaccount_display {
        display: flex;
    }    
    .myaccount_r.myaccount_display .myaccount_bg {
        flex: 0 0 50%;
        margin-right: 20px;
    }    
    .myaccount_r.myaccount_display .added-address {
        margin-top: 0px;
        width: 100%;
    }
    .address_profile_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }    
    .address_profile_title .addAddress_cls {
        margin-top: 0;
    }
    .address_profile_title .heading-type2 {
        margin-bottom: 0;
    }
    .order_dtl_l a {
        color: #000;
        text-decoration: none;
    }
    .continue-btn .popup-cont {
        padding: 15px 0;
    }
    .filter_mob_open .accordion-item {
        margin-bottom: 0;
    }
    .filter_info.accordion {
        background: #fee1ec;
        border-radius: 8px;
        overflow: hidden;
    }
    .cart_right .cart_product_list h3 {
        font-size: 14px !important;
    }
    .cart_right .cart_product_list p {
        font-size: 12px;
    }
    .cart_right .cart_product_list .prd-quantity span {
        font-size: 12px;
    }
    .cart_right .cart_product_list p.discount-price {
        font-size: 12px !important;
    }
    .cart_right .cart_product_list .item_total_price h3 {
        font-size: 12px !important;
    }
    .cart_right .cart-prd-img {
        height: 60px;
    }
    .payment_select label.custom_radio_b span.checkmark:after {
        height: 10px;
        width: 10px;
        top: 3px;
        left: 3px;
    }
    .react-tabs li.react-tabs__tab--selected .nav-link {
        color: #f6157b;
    }
    .amount_add_wallet {
        position: relative;
    }
    
    .continue-btn .popup-cont .form-group {
        margin-bottom: 0;
    }
    .amount_add_wallet .login-btn {
        display: flex;
        position: absolute;
        top: 2px;
        right: 2px;
    }
    .continue-btn .popup-cont .form-group label.form-label {
        margin-bottom: 0;
    }
    .giftCard_listing .banner-img a img {
        width: 100%;
    }
    .giftCard_listing .banner-img {
        margin-bottom: 20px;
    }
    .continue-btn.review_modal .popup-cont {
        padding: 0;
    }

    .continue-btn.review_modal .popup-cont label.form-label {
        display: block;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .continue-btn.review_modal .popup-cont .form-group {
        margin-bottom: 20px;
    }

    .continue-btn.review_modal .popup-cont .form-group svg {
        height: 30px;
        width: 30px;
        margin-right: 5px;
    }
    button.dropdown-toggle.dropdown-toggle.btn.btn-primary {
        background: transparent;
        border: 0;
        padding: 0;
        margin: 0;
    }
    .noti_space p h4 {
        font-size: 15px;
        margin-bottom: 5px;
    }
    .already_cls button {
        background: #0935c9 !important;
        border-radius: 5px;
        padding: 2px 10px;
        margin-top: 5px;
        font-size: 12px !important;
    }
    .popup-cont label h4 {
        margin-bottom: 0;
    }
    .form-text p {
        margin-bottom: 0;
        font-size: 14px;
    }
    small.form-text {
        margin-top: 0;
    }
    .tab-content.accordion-inner {
        margin-top: 0;
    }
    .tab-content.accordion-inner ul.nav.nav-tabs.nav-fill {
        border-radius: 5px;
    }
    .clear_filter:hover {
        color: #d52c6d;
    }
    .item_total_price .item-remove a img {
        max-height: 18px;
        filter: brightness(0);
    }
    .added-address .address_select .address_inner_select .btn {
        background: transparent !important;
        color: #FC2779 !important;
        border: 0 !important;
        padding: 0;
        font-size: 16px;
        min-height: unset;
        height: 100%;
    }
    .react-tel-input .flag-dropdown, .react-tel-input .selected-flag {
        border-radius: 5px 0 0 5px !important;
    }
    .form-label {
        margin-bottom: 3px;
    }
    .style-module_tooltip__tKc3i {
        margin-right:10px !important;
    }
    .notification_page .dropdown-menu.show {
	    left: auto !important;
	    right: 0 !important;
	}
	.gift-card-sec a {
	    position: relative;
	    padding-bottom: 60%;
	    overflow: hidden;
	    display: block;
	}
	.gift-card-sec a img {
        position: absolute;
	    top: 50%;
	    transform: translateY(-50%);
	    max-width: 100%;
	    max-height: 100%;
	    width: auto !important;
	}
	.owl-theme .owl-nav [class*=owl-] {
		margin: 0 !important
	}

    .no_image.review_noImg {
        border: solid 1px #dbdbdb;
        border-radius: 10px;
    }
    .brand-listing .banner-img {
        border-radius: 15px;
        overflow: hidden;
    }
    .tracking-main-class .store-dtl p.message_cls {
        margin-bottom: 0;
        font-size: 14px;
        margin-top: 2px;
    }
    .watch_ic {
        line-height: 1;
    }
    .completed.track-dtl:after {
        position: absolute;
        content: '';
        width: 12px;
        height: 6px;
        border-bottom: solid 2px #ffff;
        border-left: solid 2px #fff;
        transform: rotate(-45deg);
        z-index: 1111;
        top: 8px;
        left: 8px;
    }
    .completed.track-dtl {
        position: relative;
    }
    .order-body .cart-product a {
        text-decoration: none;
    }
    .order-body .cart-product a {
        text-decoration: none;
    }
    .space-cls.category-sec a img {
        max-width: 100px;
        max-height: unset;
        object-fit: cover;
    }
   input::-webkit-input-placeholder { /* Edge */
    color: rgb(0 0 0 / 35%) !important;
    }
    input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgb(0 0 0 / 35%)  !important;
    }
    input::placeholder {
    color: rgb(0 0 0 / 35%)  !important;
    }
    .cart_right .cart_product_list ~ .form-group {
        margin-bottom: 10px;
    }
    .space-cls.category-sec .banner-img a {
        border: 0 !important;
        border-radius: 0 !important;
        height: unset !important;
        width: unset !important;
        display: flex;
        padding: 0 !important;
    }
    .space-cls.category-sec .banner-img a img {
        max-width: 60px;
    }
    .space-cls.category-sec a .brand_img {
        border: 2px solid #c6c4fb;
        border-radius: 50%;
        height: 100px;
        overflow: hidden;
        padding: 15px;
        width: 100px;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        align-items: center;
    }
    .brand_name {
        text-align: center;
        margin-top: 10px;
    }
    
    .brand_name h5 {
        margin-bottom: 0;
        font-size: 16px;
    }
    .space-cls.category-sec a {
        text-decoration: none;
        color: #000;
    }
    .custom_react_inner_select {
        display: flex;
        align-items: center;
        padding-bottom: 5px;
    }
    .custom_react_inner_select input {
        height: 18px;
        width: 18px;
        background-color: #fff !important;
        border-radius: 5px;
        border: solid 1px #DCDCDC !important;
        margin-right: 10px;
    }
    .custom_react_slt {
        padding-left: 15px;
    }
    .select_cls label.custom_checkbox.form-label {
        font-size: 16px;
        font-weight: 500;
    }
    .fragrances-img a {
	    position: relative;
	    padding-bottom: 80%;
	}
	.fragrances-img a img {
	    object-fit: cover;
	    position: absolute;
	    top: 50%;
	    transform: translateY(-50%);
	    max-width: 100%;
	    max-height: 100%;
	}
    .wallet_list .wallet_cont h5 {
        margin: 0;
    }
    .contact_sec_info_itm {
        background: #fff;
        box-shadow: 0 0 10px #ddd;
        border-radius: 10px;
        padding: 15px;
        margin-top: 15px;
        text-align: center;
        height: calc(100% - 15px);
    }
    
    .contact_sec_info_itm .icon {
        height: 50px;
        width: 50px;
        background: #f1f1f1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 15px;
        margin: 0 auto 10px;
    }
    
    .contact_sec_info_itm  h4 {
        font-size: 20px;
        margin-bottom: 5px;
    }
    
    .contact_sec_info_itm p {
        font-size: 15px;
        margin-bottom: 0;
    }
    
    .contact_sec_info_itm p a {
        text-decoration: none;
        color: #000;
    }
    .contact-map {
        border-radius: 20px;
        overflow: hidden;
    }
    .contact_sec_info_itm p span {
        font-weight: 500;
    }
    .combo_prd.wishlist_item .col_5 {
        position: relative;
    }
    .combo_prd.wishlist_item .col_5 .arrival-prd ~ span {
        position: absolute;
        font-size: 22px;
        height: 30px;
        width: 30px;
        background: #f6157b;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        right: -15px;
        top: calc(50% - 20px);
        z-index: 111;
    }
    .combo_prd.wishlist_item .col_5:last-child .arrival-prd ~ span {
        display: none;
    }
    .combo_prd.wishlist_item .col_5:nth-child(5) .arrival-prd ~ span {
        display: none;
    }
    button.btn.btn_primary.me-2.btn.btn-primary:disabled {
        background: #7d7fc0;
        border-color: #7d7fc0;
    }
    .brand-cont p.brand-tag {
        font-size: 13px !important;
    }
    


    .cart_right .form-wrap .invalid-feedback {
        position: absolute;
        bottom: -20px;
    }
    .cart_product_list ~ .checkout-delivery-wrap {
        margin-top: 20px;
    }
    .cart_right .payment_select {
        padding: 15px;
        margin-bottom: 0;
        border-bottom: solid 1px #ffffff;
    }
    .cart_right .cart_title.checkout_title {
        padding: 15px 15px 0;
        margin-bottom: 0;
    }
    .cart_right .cart_title.checkout_title h3 span {
        margin-left: 10px;
        margin-top: 1px;
    }
    .cart_right .payment_select .custom_slt {
        display: block;
    }
    .cart_right .payment_select .custom_slt label.custom_radio_b {
        margin-right: 0 !important;
        margin-bottom: 10px;
    }
    .cart_right .payment_select .custom_slt ~ .custom_radio_b {
        padding-left: 0;
        margin-top: 20px;
        display: block;
    }
    .cart_right .payment_select .custom_slt ~ .custom_radio_b button {
        text-decoration: none;
        border: 0 !important;
        width: 100%;
    }
    .cart_right .payment_select .custom_slt ~ .custom_radio_b a {
        text-decoration: none;
    }
/* 
    .owl-carousel {

   /* .owl-carousel {
        direction: ltr;
    }
    .owl-carousel .owl-item {
        direction: rtl;
    } */

    .news_img {
        padding-bottom: 60%;
        overflow: hidden;
        position: relative;
        margin-bottom: 10px;
        border-radius: 10px;
    }
    .news_img img {
        object-fit: cover;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        max-width: 100%;
        max-height: unset;
        width: 100%;
    }
    .news_box {
        border-radius: 10px;
        overflow: hidden;
        border: solid 1px #F9BED7;
        padding: 10px;
        height: calc(100% - 15px);
        margin-bottom: 15px;
    }
    .news_content h3 {
        font-size: 18px;
        font-weight: 600;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .news_content p {
        font-size: 15px;
        font-weight: 400;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 0;
    }
    .news_ticker_sec .heading-type2 {
        color: #000;
        margin-bottom: 10px;
    }
    .address-dtl span {
        font-size: 12px;
        line-height: 1.3;
        display: block;
    }
    .notification_wrap a {
        text-decoration: none;
        color: #000;
    }
    .news-ticker-detail-page .news_img {
        padding-bottom: 25%;
    }
    .news-ticker-detail-page .news_content h3 {
        font-size: 28px;
        -webkit-line-clamp: unset;
        -webkit-box-orient: unset;
        display: block;
        text-overflow: unset;
        overflow: unset;
    }
    .news-ticker-detail-page  .news_content p {
        font-size: 18px;
        -webkit-line-clamp: unset;
        -webkit-box-orient: unset;
        display: block;
        text-overflow: unset;
        overflow: unset;
    }
    select.form-control {
        background: #fff url(http://www.inventcolab.com/perfume/assets/images/down_select.svg) !important;
        background-repeat: no-repeat !important;
        padding: 6px 25px;
        background-position: calc(100% - 10px) !important;
        background-size: 12px 6px !important;
        padding-left: 10px !important;
        appearance: unset !important;
        padding-right: 30px !important;
      }
      .form-control.is-valid, .was-validated .form-control:valid {
        border-color: #DCDCDC  !important;
    }
      .order-address-bg .cart_price {
        padding: 0;
    }
    .order-address-bg .cart_price span.price_left {
        font-size: 16px;
        font-weight: 500;
    }
    .order-address-bg .cart_price ul {
        margin-bottom: 0;
        margin-top: 10px;
    }
    .order-address-bg .cart_price .price_right {
        font-size: 16px;
    }
    .order-address-bg .cart_price ul li:last-child {
        margin-bottom: 0;
    }
    .order-address-bg .cart_price .g-total {
        padding: 0;
    }    
    .order-address-bg .cart_price .g-total li span {
        font-weight: bold;
        font-size: 18px;
    }
    .added-address .address_select .address_inner_select .btn {
        padding-left: 10px;
        min-height: unset;
        height: auto;
    }
    .edit_profile_action {
        display: flex;
        align-items: self-start;
        height: auto;
    }
    .search_dropdown_cls .arrival-prd {
        /* border: solid 1px #F9BED7; */
    }
    
    .search_dropdown_cls>div {
        margin: 15px;
    }
    .search_dropdown_cls>div>p {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 15px;
    }
    
    .search_dropdown_cls>div .banner-img {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        overflow: hidden;
        border: solid 2px #c6c4fb;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
    }
    .mike-inner {
        position: absolute;
        right: 10px;
        top: calc(50% - 12px);
        display: block;
        z-index: 11;
        height: 24px;
        display: flex;
        align-items: center;
        gap: 8px;
    }
    label.verify_btn.form-label {
        opacity: 0;
    }
    label.verify_btn.form-label + button.btn_primary.btn.btn-primary {
        min-height: 45px;
    }
/*    
    body.search_area:after {
        position: absolute;
        content: '';
        height: 100vh;
        width: 100%;
        background: rgb(0 0 0 / 34%);
        top: 150px;
    } */


.mike-inner img {
    max-height: 20px;
    /* margin-left: 8px; */
}
.mike-inner img:first-child {
    margin-left: 0;
}
.example {
    color: #f6157b;
    font-size: 20px;
    display: flex;
}
.example span {
    background: #f6157b;
    color: #fff;
    padding: 3px 5px;
    position: relative;
    font-size: 20px;
    font-weight: bold;
    margin: 0 3px;
    border-radius: 5px;
}
.example span:after {
    content: '';
    position: absolute;
    width: calc(100% - 2px);
    height: 1px;
    background: #fff;
    top: calc(50% - 1px);
    left: 1px;
}
.timer_count_bg span {
    margin: 0 1px;
}
.timer_count_bg {
    padding: 0 2px;
}
.container .inner-tit .container {
    width: 100% !important;
}
.container .inner-tit div#primary {
    width: 100% !important;
}
span.mobile_relative {
    position: relative;
}
.combo_prd.wishlist_item {
    margin-top: 30px;
}

.combo_prd.wishlist_item .arrival-prd-img img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
}

.combo_prd.wishlist_item .arrival-prd-img {
    position: relative;
    display: flex;
    padding-bottom: 80%;
    align-items: center;
    justify-content: center;
}
.nav-item-color a {
    color: #f91f7a !important;
}

  .download_sec_in {
    background: #BCB9FC;
    padding: 30px 30px 0;
    border-radius: 20px;
    max-width: 1000px;
    margin: 30px auto;
}
.d_ic svg {
    width: 20px;
    height: 20px;
}
.d_dtl h4 {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 0;
}
.d_dtl {
    margin: 10px 0 0;
}
.delivery-box-main .delivery-box {
    max-width: 33.33%;
    position: relative;
    flex: 0 0 33.33%;
}
.delivery-box-main {
    display: flex;
    width: 90%;
    margin: 50px auto;
    text-align: center;
}
.app_download_con {
    text-align: center;
}
.app_link a {
    display: inline-flex;
    margin: 0 5px;
}
.delivery-box-main .delivery-box:after {
    content: '';
    height: 30px;
    width: 1px;
    background: #000;
    display: inline-block;
    top: 10px;
    right: 0;
    position: absolute;
    z-index: 1;
}
.delivery-box-main .delivery-box:last-child::after{
    display: none;
}
.app_link {
    display: flex;
    justify-content: center;
}
.inner-tit p {
    white-space: normal;
    word-break: break-word;
}
.site-footer .footer-main.footer-layout-2 .footer-widget.col-xs-12.col-sm-6.col-lg-3 {
    width: unset !important;
}
.sale-prd {
    position: relative;
    overflow: hidden;
    border-radius: 15px;
}
.sale-prd-cont {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0000003b;
    z-index: 11;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.sale-prd-cont span.upto {
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    margin-top: 15px;
    line-height: 1;
}
.sale-prd-cont h4 {
    font-size: 38px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 1;
}
span.off {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}
li.nav-item.inner_dropdown button {
    background: transparent;
    border: 0;
    height: unset !important;
    color: #707070!important;
    font-size: 16px;
    padding: 8px 25px;
    text-transform: uppercase;
    font-weight: 400;
    display: flex;
    line-height: unset;
}
li.nav-item.inner_dropdown button:focus, li.nav-item.inner_dropdown button:hover {
    background-color: transparent !important;
    color: #f91f7a!important;
}
/* li.nav-item.inner_dropdown .dropdown:hover .dropdown-menu {
    display: block !important;
} */

li.nav-item.inner_dropdown .dropdown:hover>button {
    color: #f91f7a!important;
}
.add-btn-grp.d-flex.mt-3 {
    margin-top: 30px !important;
}
.add-btn-grp a.btn.btn_primary {
    margin-left: 15px;
}
.product_dtl_page {
    margin-top: 35px;
}
.product_dtl_page {
    margin-top: 35px;
}
.accordion-sec .accordion-description .nav.nav-tabs.nav-fill li.nav-item {
    flex: 0 0 auto !important;
    max-width: initial;
}
.prd-info {
    background: #fff;
    /* box-shadow: 0 0 15px hsl(0deg 0% 0% / 16%); */
    padding: 20px;
    border-radius: 15px;
    height: 100%;
}
.prd-info-cont h5 {
    font-size: 18px !important;
    border-bottom: solid 1px #f1f1f1;
    padding-bottom: 12px;
    margin-bottom: 15px;
}
.prd-info-cont ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.prd-info-cont p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 0;
    color: #303030;
}
.prd-info-cont ul li {
    display: flex;
    margin-bottom: 12px;
    flex: 0 0 50%;
}
.prd-info-cont ul li span {
    font-size: 16px;
}

.prd-info-cont ul li span.right-cont {
    font-weight: 500;
    color: #404040;
    margin-left: 5px;
}
.prd-info-cont ul li:last-child {
    margin-bottom: 0;
}
.subMenu {
    background-color: #ffffff;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 100%;
    visibility: hidden;
    z-index: 98;
    -webkit-box-shadow: 0 0 3px rgba(0,0,0,.20);
    -moz-box-shadow: 0 0 3px rgba(0,0,0,.20);
    box-shadow: 0 0 3px rgba(0,0,0,.20);
    -webkit-transition: -webkit-transform .3s ease, opacity .3s ease, visibility .3s ease;
    transition: transform .3s ease, opacity .3s ease, visibility .3s ease;
    -webkit-transform: translateY(14px);
    transform: translateY(14px);
    width: 200px;
}

.nav-item.inner_dropdown:hover .subMenu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

li.nav-item.inner_dropdown {
    position: relative;
}

.subMenu ul li a {
    display: block;
    padding: 8px 14px;
    color: #707070;
    text-decoration: none;
    width: 100%;
    font-size: 15px;
    border-bottom: solid 1px #f1f1f1;
}

.subMenu ul {list-style: none;padding-left: 0;margin-bottom: 0;}

.subMenu ul li:last-child a {
    border-bottom: 0;
}
.nav-item.inner_dropdown:hover > a {
    color: #f6157b;
}
/* .inner_dropdown > a::after {
    content: '';
    width: 15px;
    height: 8px;
    position: absolute;
    right: 0;
    top: 16px;
    transition: 0.5s;
    background: url(../img/arrow-down.png);
    background-size: cover;
    background-repeat: no-repeat;
    filter: brightness(0.5) invert(0.5);
}
.nav-item.inner_dropdown:hover > a::after {
    filter: unset;
} */
.cart_product_list + .checkout-delivery-wrap {
    width: 100%;
    max-width: 100%;
}
.alphabets {
    margin-left: auto;
    display: flex;
    gap: 10px;
}
.alphabets button {
    border-radius: 50%;
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    color: #fff;
    box-shadow: none !important;
    outline: 0 !important;
    border: 0 !important;
    font-size: 12px;
}


@media(max-width:  991px){
    .d_dtl h4 {
        font-size: 12px;
    }
    .download_sec_in {
        padding: 20px 20px 0;
    }
    .app_download_con h1 {
        font-size: 25px;
    }
    .delivery-box-main {
        margin: 30px auto;
        width: 100%;
    }
    .app_download_image {
        margin-top: 30px;
        text-align: center;
    }
    .app_download_image img {
        width: 250px;
    }
}
@media(max-width: 767px){
    .app_download_con h1 {
        font-size: 22px;
    }
    .delivery-box-main {
        margin: 20px auto;
    }
    .d_dtl h4 {
        font-size: 10px;
    }
    .download_sec_in {
        padding: 20px 15px 0;
    }
}





.menu_toggle {
    display: none;
}
.offcanvas-header {
    justify-content: end;
    box-shadow: 0 0 7px #00000021;
}
.offcanvas-header button.btn-close {
    height: 25px;
    width: 25px;
}
.offcanvas-body {
    padding-top: 0;
}
.alphabets button:first-child {
    background: #c6c4f9;
}
.alphabets button.active {
    background: #f91f7a;
}
.nav-item.inner_dropdown.mega_menu_dropdown {
    /* position: static; */
}
.subMenu.megaMenu {
    width: 1000px;
    left: calc(50% - 540px);
    padding: 20px;
}
.subMenu.megaMenu ul {
    display: flex;
    flex-wrap: wrap;
}
.subMenu.megaMenu ul li {
    flex: 0 0 calc(100% / 5);
    padding: 0 10px;
}
.subMenu.megaMenu ul li a {
    padding: 10px 0;
}
.nav-item.inner_dropdown:hover .subMenu.megaMenu:after {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    top: -20px;
    z-index: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #d8d8d8;
    left: calc(50% - -35px);
    opacity: 1;
    transition: 0.5s;
}
.infinite-scroll-component {
    display: flex;
    flex-wrap: wrap;
}




.cart_product_list .cart-product:first-child {
    padding-top: 0;
}

.cart_product_list .cart-product:last-child {
    border-bottom: 0;
    padding-bottom: 0;
}
.subscribe-sec .row {
    align-items: center;
}
button.btn.btn_cross_inner {
    /* position: absolute;
    right: 10px; */
    height: 35px;
    width: 35px;
    background: #f07;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    min-width: 35px;
    max-width: 35px;
    padding: 0;
    /* top: 10px; */
    line-height: 0;
    z-index: 99;
    margin-left: auto;
}
.passShowHideBtn {
    cursor: pointer;
    width: 21px;
    height: 21px;
    bottom: 12px;
    right: 12px;
    z-index: 10 !important;
}
.fixedHeader.fixed_header {
    position: fixed;
    top: 0;
    background: #fff;
    width: 100%;
    transition: all 0.5s;
    z-index: 11111;
    border-top: 0 !important;
}
.arrival-price p.discount-price span {
    text-decoration: line-through;
    color: #f6157b;
    font-weight: bold;
    font-size: 15px;
    margin-right: 5px;
}
.q_btn_group input::-webkit-outer-spin-button,
.q_btn_group input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.q_btn_group input[type=number] {
  -moz-appearance: textfield;
}
.cart_prd_right.cart_price_box {
    flex: 0 0 320px;
  }
  .card_price_main {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  .card_price_main .arrival-price {
    margin-left: 10px;
  }
  .card_price_main h3 {
    color: #000;
    font-size: 15px;
  }
  
.cart_product_list .prd-quantity {
    margin-top: 10px;
  }
  .cart_product_list .arrival-title p {
    padding-top: 7px;
  }

  .addressOuter{
    padding: 20px 0;
  }
  .addressOuter .sectionHeading{
    font-size: 24px;
    font-weight: 600;
  }
  .addressOuter .form-label{
    font-size: 15px;
    color: #9e9e9e;
  }
  .addressOuter .form-control{
    font-size: 15px;
    /* min-height: 40px !important; */
    color: #5a5a5a;
    transition: ease-in-out .3s;
  }
  .addressOuter .form-control.textArea{
  }
  .addressOuter .form-control::placeholder {
    font-size: 15px;
    color: #b4b4b4 !important;
    font-weight: 300 !important;
  }
  .addressOuter .form-control:focus {
    border-color: #f07;
  }

  .selectedProducts {
    padding: 15px 15px 0;
  }
  .selectedProducts .items .detail .imgOuter{
    width: 36px;
    height: 46px;
    flex: 0 0 100%;
    max-width: 46px;
  }
  .selectedProducts .items .detail .imgOuter img{
    object-fit: cover;
    object-position: top center;
  }
  .selectedProducts .items .detail .title{
    font-size: 13px;
    /* font-weight: 300; */
    max-width: 170px;
  }
  .selectedProducts .items .price{
    font-size: 15px;
    white-space: nowrap;
  }

  .addressOuter .cart_right{
    flex: 0 0 100%;
  }

  @media(max-width: 991.98px){
      .addressOuter .sectionHeading{
        font-size: 20px;
      }
  }
  @media(max-width: 767.98px){
    .addressOuter .sectionHeading{
      font-size: 18px;
    }
    .selectedProducts .items .detail .imgOuter{
        width: 36px;
        height: 36px;
        max-width: 36px;
      }
      .selectedProducts .items .price{
        font-size: 14px;
      }
}


.tosterOuter>div {
    z-index: 1111005 !important;
}

.view_all {
    position: absolute;
    right: 0;
    top: -3px;
}

.title-search{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}
.title-search + a{
    color: #000;
    text-decoration: none;
}

.link-search{
    margin-bottom: 2px;
    margin-right: 2px;    
    text-decoration: none;
    color: #f07;
    max-width: 100%;
}
.arrival-search-cont {
    min-height: 80px;
}
.link-search > .badge {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}
.search_dropdown_cls .owl-carousel .owl-nav div {
    height: 30px;
    width: 30px;
}