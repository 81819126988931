@media(min-width:  768px){
	.navbar-expand-lg .navbar-collapse {
		display: flex !important;
	}
}
@media (max-width: 1680px) {
	.search_dropdown_cls>div .banner-img {
		height: 90px;
		width: 90px;
	}
	.category-sec .banner-img {
	    height: 90px;
	    width: 90px;
	}
    .myaccount_r.myaccount_display .myaccount_bg {
		flex: 0 0 55%;
	}
	.profile_bg {
		padding: 15px;
		flex: 0 0 220px;
		max-width: 220px;
	}
	.profile_img {
		height: 130px;
		width: 130px;
	}
	
	.user-contact-dtl span {
		font-size: 12px;
	}
	
	.user-contact-dtl a {
		font-size: 14px;
	}
	
	.user-profile-dtl h3 {
		margin-bottom: 10px;
	}
	
	.added-address .address_select .address_inner_select {
		padding: 10px;
	}
	
	.added-address .address_select .address_inner_select .btn {
		padding: 0 10px;
		font-size: 14px;
		text-transform: capitalize;
	}
	
	.address_inner_select label.custom_radio_b {
		padding-right: 10px;
	}
	
	.user-contact-dtl div {
		padding: 0 10px;
	}
}
@media(max-width:  1450px){
	.search_dropdown_cls>div .banner-img {
		height: 85px;
		width: 85px;
	}
	.category-sec .banner-img {
	    height: 75px;
	    width: 75px;
	}
	.combo_prd.wishlist_item .col_5 {
		flex: 0 0 20%;
	}
	.fragrances-title p {
	    font-size: 12px;
	}
	.cart_wrap .btn_primary {
		font-size: 12px;
	}
	.myaccount_r.myaccount_display {
		flex-direction: column;
	}
	.myaccount_r.myaccount_display .added-address {
		margin-top: 15px;
	}
	.myaccount_r.myaccount_display .myaccount_bg {
		flex: unset;
		margin-right: 0;
	}
	.card_right_wrap {
	    flex: 0 0 300px;
	}
	.card_left_wrap {
	    flex: 0 0 calc(100% - 300px);
	}
	.navbar-light .navbar-nav .nav-link {
		padding: 8px 15px;
	}
}
@media(max-width:  1366.98px){
	.category-sec .banner-img {
		height: 80px;
		width: 80px;
		padding: 10px;
	}
	.rating_r_space {
		padding: 0 5px 0 40px
	}
	.rating_con {
	    font-size: 20px;
    }
    .category-sec .col_1 {
	    flex: 0 0 calc(100% / 8);
	    max-width: calc(100% / 8);
	}
	.checkout-delivery-wrap{
		padding: 15px 100px;
	}
	.btn_primary {
	    font-size: 14px;
	}
	.rating_con p {
		font-size: 14px;
	}
	.app-dtl-wrap {
		padding: 10px 20px
	}
	.app_title h3 {
	    font-size: 16px;
	}
	.prd-dtl-cont .checkout-delivery-wrap {
	    padding: 15px 20px;
	}
	.navbar-light .navbar-nav .nav-link {
	    padding: 8px 15px;
	}
}
@media(max-width:  1199.98px){
	.nav-item.inner_dropdown:hover .subMenu.megaMenu:after{
		display: none;
	}
	.subMenu.megaMenu {
		width: 950px;
		left: calc(50% - 475px);
	}
	section.space-cls.cart-page .inner-tit {
		flex-wrap: wrap;
	}
	.alphabets button {
		height: 22px;
		width: 22px;
		font-size: 12px;
	}	
	.alphabets {
		gap: 5px;
	}
	.timer_count_bg span {
	    font-size: 16px;
	    padding: 5px 5px;
	}
	.search-wrap .form-control {
		min-width: 570px !important;
		padding-right: 60px !important;
		padding-left: 10px !important;
	}
	.checkout-delivery-wrap {
		padding: 15px 80px;
	}
	.checkout-delivery-wrap .checkout-col {
		flex: 0 0 50%;
	}
	.fav_wrap a {
	    height: 30px;
	    width: 30px !important;
	}
	.my-card-sec {
	    flex-wrap: wrap;
	}

	.card_left_wrap {
	    flex: 0 0 100%;
	    padding: 0;
	}

	.card_right_wrap {
	    flex: 0 0 100%;
	    padding-left: 0;
	    border-left: 0;
	    margin-top: 15px;
	    border-top: solid 1px #ddd;
	    padding-top: 15px;
	}
	.main-prd-left {
	    flex: 0 0 60%;
	    max-width: 60%;
	}
	.share-option .btn-check:checked+.btn, .share-option .btn.active, .btn.show, .share-option.btn:first-child:active, .share-option :not(.btn-check)+.btn:active {
		background: transparent !important;
		padding: 0;
	}
	.share-option .dropdown-toggle::after {
		display: none;
	}
	.product-inner-dtl ul li {
	    padding: 0 15px;
	}
	.prd_size_label label.custom_radio_b {
	    font-size: 14px;
	}
	.q_btn_group button {
	    height: 30px;
	    width: 30px;
	}
	.gift-title {
	    background-size: 100% 100% !important;
	}
	.navbar-light .navbar-nav .nav-link {
	    padding: 10px 15px;
	    font-size: 13px;
	}
	.price-sec .row [class*="col-"] {
	    margin: 7px 0;
	}
	.timers ul li {
	    font-size: 18px;
	}
	.timers ul li span {
	    font-size: 18px;
	}
	.category-sec .col_1 {
	    flex: 0 0 calc(100% / 6);
	    max-width: calc(100% / 6);
	}
	.space-cls.category-sec a .brand_img {
		height: 100px;
		width: 100px;
		margin: 0 auto;
	}
	.heading-type1, .heading-type1 a {
	    font-size: 20px;
	}
	.heading-type2 {
	    font-size: 18px;
	}
	.prd-wrap .prd-title h4 {
	    font-size: 14px;
	    padding: 5px 10px;
	}
	.gift-title h5 {
	    font-size: 14px !important;
	}
	.gift-title h6 {
	    font-size: 12px !important;
	}
	.owl-carousel .owl-nav div {
	    height: 40px;
	    width: 40px;
	    top: calc(50% - 20px);
	    left: 0;
	}

	.owl-carousel .owl-nav .owl-next {
	    right: 0 !important;
	}

	.accessories-cont h3 {
	    font-size: 15px;
	}

	.accessories-cont p {
	    font-size: 13px;
	}

	.arrival-title h3 {
	    font-size: 14px;
	}

	.arrival-title p {
	    font-size: 12px;
	}

	.arrival-price p {
	    font-size: 13px;
	}

	.arrival-price p.discount-price {
	    font-size: 12px !important;
	}
	.prd-add-option {
		margin-top: 0
	}
	.rating_thumb {
	    flex: 0 0 90px;
	}
	.rating_con h4 {
	    font-size: 26px;
	}
	.rating_cont {
	    margin-top: 20px;
	}
	.rating_cont p {
	    font-size: 14px;
	}
	.footer-title h3 {
	    font-size: 16px;
	}
	.footer-link ul li a {
	    font-size: 14px;
	}
	.footer-link ul li {
	    margin-bottom: 8px;
	}
	.newsletter-wrap label {
	    font-size: 17px;
	}
	.app_title h3 {
	    font-size: 14px;
	    margin-top: 10px;
	}
	#countdown ul li {
	    font-size: 16px;
	}
	.thankyou_sec_in h1 {
	    font-size: 45px;
	}
	.thankyou_sec_in p {
	    font-size: 22px;
	}
	.thankyou_sec_in {
	    max-width: 320px;
	}
	.thankyou_sec_in img {
	    max-width: 60px;
	}
	.arrival-cont {
		min-height: 115px;
	}
	.arrival-search-cont {
		min-height: 60px;
	}
}
@media(max-width: 991.98px) {
	.alphabets {
		flex-wrap: wrap;
		margin-top: 10px;
	}
	.subMenu.megaMenu ul li {
		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: calc(100% / 4);
		padding: 0 10px;
	}
	.subMenu.megaMenu {
		width: 100%;
		left: calc(50% - 360px);
	}
	.prd-dtl-bottom-cont {
		flex-wrap: wrap;
	}
	
	.prd-dtl-bottom-cont > div {
		max-width: 100%;
		flex: 0 0 100%;
	}
	.sold_dtl p{
		font-size: 11px;
	}
	.slideshow-thumbnails {
		width: 120px !important;
		padding: 4px !important;
		border: solid 1px #ddd !important;
		margin: 10px 2px 0;
		border-radius: 5px;
		height: 120px;
		object-fit: cover;
	}
	.timer_count_bg span {
		font-size: 14px;
		padding: 4px 4px;
	}
	.category-sec .col_1 {
	    flex: 0 0 calc(100% / 4);
	    max-width: calc(100% / 4);
	}
	.space-cls.category-sec a .brand_img {
		height: 100px;
		width: 100px;
		margin: 0 auto;
	}
	.combo_prd.wishlist_item .col_5 {
		flex: 0 0 auto;
	}
	.combo_prd.wishlist_item .col_5:nth-child(3) .arrival-prd ~ span, .combo_prd.wishlist_item .col_5:nth-child(6) .arrival-prd ~ span {
		display: none;
	}
	.combo_prd.wishlist_item .col_5:nth-child(5) .arrival-prd ~ span {
		display: flex;
	}
	.order_download .btn {
		padding: 0 10px;
	}
	main.prd_detail_space + footer {
        margin-bottom: 120px;
    }
	.amount_add_wallet .login-btn {
		top: 5px;
		right: 5px;
	}
	.app-icon {
		height: 35px;
	}
	.order-body .cart-prd-img {
	    flex: 0 0 75px;
	    max-width: 75px;
		height: 75px;
	}
	.item_total_price .order-actions .btn {
		padding: 0 10px;
	}
	.order_wrap .cart_prd_right {
		flex: 0 0 80px;
		text-align: right;
		justify-content: end;
	}
	.order-id {
		font-size: 20px;
	}
	.date_time {
		font-size: 16px;
	}
	.order_payment {
		font-size: 15px;
	}
	.order_right .order-status a.btn.btn_primary {
		padding: 0 10px;
	}
	.bottom_header .container {
		position: relative;
	}
	.category-sec .banner-img {
		height: 140px;
		width: 140px;
	}
	.bottom_header {
		padding: 5px 0
	}
	.footer-social-sec ul {
	    padding-left: 0;
	}
	.newsletter-wrap .input-group {
	    display: block;
	}
	.newsletter-wrap .input-group label {
	    margin-right: 0;
	    margin-bottom: 5px;
	}
	.myaccount_dtl {
	    flex-wrap: wrap;
		height: auto;
	}
	.myaccount_dtl .profile_bg {
	    margin: 0 auto 15px;
	}
	.user-profile-dtl {
	    width: 100%;
	    border-top: solid 1px #a7acaf;
	    padding-top: 15px;
	    margin-top: 5px;
	}
	.accordion-description .nav-tabs .nav-link {
	    font-size: 14px;
	    padding: 7px 5px;
	}
	.wallet_bal {
	    padding: 0 10px;
	}
	.wallet_r {
	    flex: 0 0 40px;
	}
	.wallet_inner {
	    padding: 10px;
	}
	.wallet_cont span {
	    font-size: 13px;
	}
	.wallet_bal span.font-bold {
	    font-size: 14px;
	}
	.wallet_cont h5 {
	    font-size: 16px;
	}
	.offer-img {
		padding-bottom: 22%;
	}
	.download-wrap {
	    padding-right: 40px;
	}
	.bg-gray {
	    padding: 15px;
	}
	.light-gray {
	    padding: 15px;
	}
	.address_select {
	    padding: 15px;
	}
	.form-control {
	    font-size: 14px;
	    padding: 10px 15px;
	}
	.address-toggle span {
	    font-size: 12px;
	    line-height: 1.2;
	    margin-left: 5px;
	}
	.address-toggle label {
		width: 35px;
		height: 20px;
	}
	.address-toggle label:after {
		height: 10px;
		width: 10px;
		top: 5px;
	}
	.locate-wrap a {
	    font-size: 12px;
	}
	.locate-wrap a span.location-icon img {
	    max-width: 20px;
	}
	.address_inner_select a {
	    font-size: 14px;
	}
	.form-group {
	    margin-bottom: 15px;
	}
	#slideshow-items-container {
	    position: relative;
	    text-align: center;
	    margin: 0 auto 10px;
	    display: block;
	}
	.prd-dtl-cont {
	    padding-left: 0;
	    padding-top: 25px;
	}
	.prd-perfume-box h4 {
	    font-size: 12px;
	    margin-top: 10px;
	}
	.prd-perfume-box {
		padding: 10px 10px 10px;
		border-radius: 10px;
	}
	.prd-perfume-img {
	    padding-bottom: 90%;
	}
	.payment_select .custom_slt {
	    flex-direction: column;
	}
	.payment_select label.custom_radio_b {
	    margin-bottom: 10px;
	}
	.checkout-delivery-wrap {
	    padding: 10px 15px;
	}
	.checkout-box {
	    flex-direction: column;
	}
	.checkout-box span {
	    font-size: 12px;
	    margin-top: 5px;
	    line-height: 1.2;
	}
	.checkout-box span.c-icon {
	    margin: 0;
	    min-height: 30px;
	}

	.cart_right .cart_product_list {
    	padding: 0 10px 15px;
	}
	.item_total_price h3 {
	    font-size: 12px;
	}

	.item-remove {
	    flex: 0 0 65px;
	}
	.cart_title h3 {
	    font-size: 16px;
	}
	.text_bold {
	    font-size: 14px;
	}
	.custom_slt .custom_radio_b {
	    font-size: 15px;
	}
	.address-dtl span {
	    font-size: 13px;
	}
	.cart_login p {
	    font-size: 13px;
	}
	.cart_right {
	    flex: 0 0 300px;
	}
	.cart_left {
	    flex: 0 0 calc(100% - 320px);
	    max-width: calc(100% - 320px);
	}
	.cart_login_btn {
	    flex: 0 0 130px;
	}
	.cart_login_btn .btn.btn_primary {
	    padding: 10px 10px;
	}
	.gift-code .form-wrap .form-control {
	    font-size: 14px;
	}
	.cart-address-wrap {
	    padding: 10px;
	}
	.cart_address_bg {
	    padding: 10px;
	}
	.cart_login {
	    padding-right: 10px;
	}
	.others-option .offer a img {
	    max-width: 70px;
	}
	.close-ic {
	    display: none;
	}
	.navbar-toggler {
	    display: none;
	}
	
	.navbar-nav {
	    flex-direction: row;
	}
	.navbar-collapse {
	    flex-basis: auto !important;
	}
	.navbar-light .navbar-nav .nav-link {
	    padding: 10px 15px;
	    font-size: 12px;
	}
	.header_list {
	    padding: 0px 10px;
	}
	.head_icon img {
	    max-height: 15px;
	}
	.header_contact_info {
	    margin-right: 15px;
	}
	.head_top_cont a {
		font-size: 14px;
	}
	.header_login_cls select {
	    font-size: 14px;
	}
	.header_list a {
	    font-size: 12px;
	}
	.head_icon {
	    margin-right: 5px;
	}
	.category-sec .owl-prev {
	    left: 0 !important;
	}
	.category-sec .owl-carousel .owl-nav .owl-next {
	    right: 0 !important;
	}
	.banner-sec .owl-carousel .owl-nav div {
	    left: 40px !important;
	}
	.banner-sec .owl-carousel .owl-nav .owl-next {
	    right: 40px !important;
	    left:auto !important;
	}
	.btn_primary {
	    font-size: 13px;
	    min-height: 35px;
	}
	.fav_wrap a {
	    height: 35px;
	    width: 35px !important;
	}
	.fragrances-title p {
	    font-size: 15px;
	}
	.search-wrap .form-control {
	    min-width: 380px !important;
	    padding-right: 40px;
	}
	.app-dtl-wrap {
		margin-right: 0
	}
	.gift-card-img {
		padding: 0
	}
	.banner-seller .brand-cont {
	    max-width: 100%;
	    left: unset;
	} 
	.essentials-box h4 {
	    font-size: 18px;
	}
	.others-option .offer {
	    display: none !important;
	}
	.others-option {
		background: none !important;
	}
	.cart_product_list .cart-product .cart-prd-img {
		flex: 0 0 65px;
		max-width: 65px;
		height: 65px;
	}
	.cart_product_list .cart-product .card-prd-cont {
		flex: 0 0 calc(100% - 155px);
	}
	.cart_product_list .cart-product .cart_prd_right {
		margin-left: 0;
		max-width: 80px;
		flex: 0 0 80px;
	}
	.inner-tit #main-content ol, .inner-tit #main-content ul {
		margin-left: 0 !important;
		margin-right: 1.5em;
	}
}
@media(max-width: 767.98px){
	.subMenu.megaMenu ul li {
		flex: 0 0 100%;
	}
	.subMenu.megaMenu{
		padding: 0;
	}
	.menu_toggle {
		display: flex;
	}
	.subMenu ul a {
		padding: 10px 10px !important;
		border-color: #f1f1f1 !important;
	}
	.subMenu ul a {
		padding: 10px 10px !important;
		border-color: #f1f1f1 !important;
	}
	
	span.menu_toggle {
		position: absolute;
		top: 0px;
		width: 50px;
		right: 0;
		border-left: solid 1px #f1f1f1;
		height: 55px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	span.menu_toggle img {
		max-width: 15px;
		filter: brightness(1) invert(0.5);
	}
	.nav-item.inner_dropdown a.nav-link {
		padding-right: 50px;
	}
	.subMenu {
		position: unset;
		display: none;
		opacity: 1;
		visibility: visible;
		width: 100%;
		box-shadow: none;
		border: solid 1px #f1f1f1;
		margin-bottom: 15px;
		transition: all 0.5s;
	}
	.nav-item.inner_dropdown:hover .subMenu {
		display: block;
		transition: all 0.5s;
	}
	.nav-item.inner_dropdown:hover > a{
		color:#212529
	}
	.prd-info {
		margin-bottom: 20px;
		height: unset;
	}

	body .login_after .dropdown-menu.dropdown-menu {
		background: #fff;
		border-radius: 0;
		height: 100vh;
		max-width: 320px;
		overflow: auto!important;
		padding: 0;
		position: fixed !important;
		right: -100% !important;
		top: 0 !important;
		transition: all .5s;
		width: 100%;
		z-index: 1111;
		inset: unset !important;
	    transform: unset !important;
		display: block !important;
		transition: all 0.5s !important;
		opacity: 0 !important;
	}
	.open_menu .dropdown-menu.dropdown-menu button.btn_cross {
		margin: 20px 0px 10px 20px;
	}
	
	.login_after .dropdown-menu.show {
		top: 0 !important;
	}

	.login_after .dropdown-menu li .dropdown-item {
		flex-direction: unset;
		text-align: left;
		align-items: center;
		justify-content: start;
	}
	.open_menu .login_after .dropdown-menu.dropdown-menu {
		right: 0 !important;
		transition: all .5s !important;
		top: 0!important;
		opacity: 1 !important;
	}
	body .js-image-zoom__zoomed-image, .js-image-zoom__zoomed-area {
		display: none !important;
	}
	.category-sec .banner-img {
	    height: 120px;
	    width: 120px;
	}
	.search_dropdown_cls>div .banner-img {
		height: 80px;
		width: 80px;
	}
	.mobile_menu_ic {
        margin-top: -5px;
    }
	.order-address-bg .cart_price span.price_left {
		font-size: 14px;
	}
	
	.order-address-bg .cart_price .price_right {
		font-size: 14px;
	}
	.order-address-bg .cart_price .g-total li span {
		font-size: 16px;
	}
	.disc-code p {
		font-size: 12px;
		display: inline-block;
		text-align: center;
	}
	.disc-code p {
		font-size: 12px;
	}
	.disc-code p .coupon-code {
		font-size: 14px;
	}
	.brand-listing .banner-img {
		border-radius: 10px;
	}
	.mobile_menu_ic img {
	    max-width: 24px;
	}
	.noti_space {
		border-radius: 10px;
		margin-top: 10px;
		padding: 10px;
	}
	.cart_product_list .cart-product {
		flex-wrap: unset;
	}
	.cart_product_list .cart-product .item_total_price {
		flex-direction: column;
		text-align: right;
		justify-content: end;
		align-items: end;
	}
	.custom_radio_b .checkmark:after {
		top: 3.5px;
	}
	.download-store a.nav-link {
		border: 0 !important;
		padding: 0 !important;
	}
	.navbar-nav li.nav-item a {
		display: flex;
		align-items: center;
		padding: 10px 0;
		border-bottom: solid 1px #afafaf;
		text-transform: uppercase;
		font-size: 13px;
	}

	.navbar-nav li.nav-item {
		padding: 0;
	}
	.address_inner_select {
		flex-wrap: wrap;
	}
	.added-address .address_select .address_inner_select {
		padding: 15px;
	}
	.added-address .address_select .address_inner_select a.btn.btn_primary {
		margin-left: 0;
		margin-top: 10px;
	}
	.added-address .address_select .address_inner_select button.btn.btn_primary.btn.btn-primary {
		margin-top: 10px;
	}
	.added-address .address_select .address_inner_select label.custom_radio_b {
		flex: 0 0 100%;
	}
	.prd-cont-sec .add-btn-grp button.btn.btn_primary.me-2.btn.btn-primary {
        width: 100%;
    }
	.rating_title {
		font-size: 50px;
	}
	.cart_title.checkout_title h3 {
		font-size: 13px;
	}
	label {
		font-size: 13px;
		margin-bottom: 5px !important;
	}
	.offer-banenr-sec .banner-img {
		border-radius: 10px !important;
		padding-bottom: 25%;
	}
	.owl-carousel .owl-nav div, .owl-carousel .owl-nav .owl-next {
		background-size: 7px 10px !important;
	}
	.navbar-nav {
		padding-right: 0 !important;
	}
    div#offcanvasNavbar-expand-md {
        position: fixed;
        overflow: auto;
        height: 100vh;
        width: 100%;
        max-width: 280px;
        z-index: 1111;
    }
    .offcanvas-backdrop {
        z-index: 1111;
    }
	.clear_filter {
		padding: 2px 5px;
		border: solid 1px #f6157b;
		border-radius: 5px;
		margin-right: 10px
	}
	.cart_title span {
		margin-right: 0 !important
	}
	.cart_title span.bag_cont {
	    display: none;
	}
	.cart_title .bag_count {
	    margin-right: 0 !important;
	    position: absolute;
	    top: -10px;
	    right: -5px;
	    background: #f6157b;
	    height: 22px;
	    width: 22px;
	    border-radius: 50%;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    color: #fff;
	    font-size: 12px;
	}
	.cart_title h3 {
	    position: relative;
	}
	.select-gift-card .custom_radio_b .checkmark .gift-card-img {
		padding-bottom: 100%;
	}
	.accordion-sec .accordion-description .nav-tabs {
	    padding-bottom: 0;
	}
	/* .login_after.dropdown {
	    display: none;
	} */
	.desktop-menu {
		display: none;
	}
	.login_before {
	    display: block !important;
	}
	.a_sidebar {
	    position: fixed;
	    top: 0;
	    right: -100%;
	    width: 100%;
	    border-radius: 0;
	    z-index: 11111;
	    transition: all .5s;
	    height: 100vh;
	    overflow: auto !important;
	    background: #ffffff;
	    max-width: 320px;
	    padding: 15px;
	}
	.open_account_f .a_sidebar {
	    right: 0;
    	transition: all .5s;
	}
	.tracking-main-class .store-dtl h4 {
	    font-size: 14px;
	}
	.order_wrap .cart_prd_right{
		margin-bottom: 15px;
		justify-content: end;
		flex: 0 0 120px;
	}
	.tracking-main-class p.font18m {
	    font-size: 13px;
	}
	.my-account-inner {
	    flex-wrap: wrap;
	}

	.myaccount_l {
	    flex: 0 0 100%;
	    max-width: 100%;
	}

	.order-page-dtl .order-id {
	    font-size: 14px;
	    margin-bottom: 2px;
	}

	.order-page-dtl .date_time {
	    font-size: 14px;
	    margin-bottom: 0;
	}

	.order-page-dtl .order_payment {
	    font-size: 13px;
	}

	.order_download .btn {
	    padding: 10px 10px;
	}

	.locate-wrap a span.location-icon {
	    margin-right: 5px;
	}

	.locate-wrap a span.location-icon svg {
	    width: 18px;
	    height: 18px;
	}

	.order_download .locate-wrap {
	    margin-top: 0;
	}

	.myaccount_r {
	    padding: 0;
	}
	.download_app .download-wrap h3.heading-type2 {
	    display: none;
	}
	.download_app .download-wrap {
	    margin-top: 10px;
	}
	.nav-item.mobile_menu .copyright-cont h4 {
	    color: #000;
	    margin-top: 10px;
	    font-size: 13px;
	    font-family: 'Roboto', sans-serif !important;
	    font-weight:500;
	}
	.sort_by .accordion-collapse, .sort_by .accordion-collapse.collapse {
	    display: block !important;
	}

	.sort_by .accordion-collapse, .sort_by .accordion-button::after {
	    display: none;
	}
	.open_sort .sort_by h2.accordion-header:after {
	    position: absolute;
	    content: '';
	    width: 100%;
	    height: 100%;
	    top: 0;
	    left: 0;
	}
	.open_sort .sort_by h2.accordion-header {
	    position: relative;
	}
	.filter_main {
	    margin: 0 auto;
	    display: flex;
	    justify-content: center;
	}
	.filter_pop a {
	    padding: 0 15px;
	    text-decoration: none;
	    color: #000;
	    font-size: 12px;
	    font-weight: 500;
	    display: flex;
	    align-items: center;
	    border-right: solid 1px #bebebe;
	}
	.filter_pop a img {
		max-height: 18px;
		margin-right: 5px;
	}
	.filter_pop a:last-child {
	    border-right: 0;
	}
	.add-btn-grp {
	    position: fixed;
	    bottom: 65px;
	    width: 100%;
	    padding: 10px 15px;
	    background: #ddedf5;
	    margin: 0 auto;
	    align-items: center;
	    left: 0;
	    z-index: 1111;
	    box-shadow: 0px -5px 20px rgb(0 0 0 / 13%);
	}
	.product_detail_body .bottom-footer {
	    margin-bottom: 120px;
	}
	.main-prd-right {
	    margin-top: 15px;
	}
	.main-prd-left {
	    flex: 0 0 100%;
	    max-width: 100%;
	}
	.prd-cont-sec {
	    flex-wrap: wrap;
	}
	.checkout-col {
	    flex: 0 0 50%;
        padding: 0 15px;
	}
	.prd-title {
	    margin-bottom: 0;
	}
	.main-prd-left p {
	    margin-bottom: 10px;
	}
	.prd-size {
	    padding: 10px 0;
	}
	.mobile_fix_menu {
	    position: fixed;
	    left: 0;
	    width: 100%;
	    justify-content: space-between;
	    padding: 10px 15px !important;
	    background: #fff;
	    bottom: 0;
	    z-index: 1111;
	    box-shadow: 0px -5px 10px rgb(0 0 0 / 20%);
	}
	.head-right ul li a {
	    text-align: center;
	    color: #000;
	    text-decoration: initial;
	    font-size: 14px;
	}
	.head-right ul li:first-child {
	    margin-left: 0;
	}
	.head-right ul li a.active {
    	color: #f91f7a;
	}
	.head-right ul li a.active path {
	    fill: #f91f7a;
	}
	.desktop_menu_none {
		margin-top: 3px;
		display: block;
		line-height: 1.5;
	}
	.login_after.dropdown a {
		justify-content: center;
		flex-direction: column;
	}
	.login_after.dropdown .btn {
		height: unset;
		flex-direction: column;
		color: #000;
	}
	.download-store {
		margin-top: 0
	}
	.progress-wrap .woody-bg {
	    width: 100%;
	    max-width: 100%;
	    white-space: nowrap;
	}
	.progress-wrap div {
	    min-width: 100px;
	    text-align: center;
	    border-radius: 0;
	}
	.add-btn-grp .btn {
	    width: 100%;
	}
	/* .arrival-prd-img a {
		padding-bottom: 130%;
	} */
	.arrival-prd-img{
		margin-bottom: 0
	}
	.arrival-cont {
	    min-height: 105px;
	}
	.nav-item.mobile_menu .header_login_cls {
	    padding: 12px 0 8px;
	}
	.nav-item.mobile_menu .country-slt select {
	    text-align: left;
	    appearance: auto;
	    padding: 10px 10px;
	    font-size: 14px;
	}
	.nav-item.mobile_menu .header_login_cls {
	    display: flex;
	}
	.nav-item.mobile_menu .header_login_cls .select {
	    width: 50%;
	    padding-left: 15px;
	}
	.nav-item.mobile_menu .header_login_cls select {
	    width: 50%;
	     padding: 3px 5px;
	    text-align: left;
	    appearance: auto;
	}
	.others-option {
	    display: none;
	}
	.nav-item.mobile_menu {
	    display: block;
	}
	/*.banner-img {
	    position: relative;
	    overflow: hidden;
	    padding-bottom: 50%;
	}*/
	.download-wrap {
		padding: 0
	}
	.locate-wrap {
	    text-align: left;
	    margin-top: 10px;
	}
	.locate-wrap a {
	    justify-content: left;
	}
	.prd-perfume-sec [class*="col-"] {
	    flex: 0 0 33.33%;
	    margin: 5px 0;
	}
	.rating-left {
	    padding: 0 15px;
	    flex: 0 0 35%;
	    max-width: 35%;
	}
	.rating-right {
	    padding: 0 15px 0 40px;
	    flex: 0 0 65%;
	    max-width: 65%;
	}
	.product-inner-dtl ul li {
	    padding: 0 10px;
	    display: flex;
    	align-items: center;
	}
	.quantity_sec h4{
		flex: 0 0 70px;
		margin-bottom :0
	}

	.product-inner-dtl ul li span {
	    font-size: 12px;
	}
	.cart-page .cart_title {
		margin-bottom: 0;
		position: absolute;
		top: -160px;
		right: 15px;
		flex-direction: row-reverse;
	}
	.cart_title.checkout_title {
		position: unset;
		display: block;
		margin-bottom: 10px;
	}
	.cart-page .container {
	    position: relative;
	}
	.cart_body .navbar-toggler {
		top: -40px !important
	}
	.g-total p{
		margin-bottom: 10px
	}
	form.address_form {
	    margin-top: 10px;
	}
	.product_wrap {
	    flex-direction: column;
	}
	.product_right_s {
	    flex: 0 0 100%;
	    max-width: 100%;
	    margin: 0;
	    padding: 0;
	}
	.product_left_s {
	    flex: 0 0 100%;
	    max-width: 100%;
	}
	.filter_pop {
	    display: flex;
	    background: #ffffff;
	    justify-content: center;
	    align-items: center;
	    position: fixed;
	    bottom: 80px;
	    z-index: 111;
	    border-radius: 35px;
	    height: 40px;
	    box-shadow: 0 0 20px hsl(0deg 0% 0% / 35%);
	    padding: 5px 10px;
	    width: auto;
	}
	.filter_info, .sort_by {
	    position: fixed;
	    top: 0;
	    left: -100%;
	    width: 100%;
	    border-radius: 0;
	    z-index: 11111;
	    transition: all .5s;
	    height: 100vh;
	    overflow: auto !important;
	    background: #ffffff;
	    max-width: 280px;
	    padding: 15px;
	}
	button.btn_cross {
		background: #fee1ec;
		height: 40px;
		width: 40px;
		margin-bottom: 10px;
		border-radius: 7px;
		margin-left: auto;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.side-filter button.btn_cross img {
	    max-width: 15px;
	}
	.open_filter .filter_info {
	    left: 0;
	    transition: all .5s;
	}
	.open_sort .sort_by {
	    left: 0;
	    transition: all .5s;
	}

	.open_filter, .open_sort {
	    overflow: hidden;
	}
	.side-filter .filter_info {
	    background: #fff !important;
	    padding: 15px;
	    border-radius: 0 !important;
	}
	.open_filter::after, .open_sort::after, .open_account_f:after, .open_menu:after {
	    height: 100vh;
	    background: #000000b5;
	    width: 100%;
	    content: '';
	    position: fixed;
	    z-index: 111;
	    top: 0;
	}
	.filter_pop h3 {
	    margin-bottom: 0;
	    font-size: 15px;
	}
	.cart_left {
	    flex: 0 0 100%;
	    max-width: 100%;
	}
	.cart_right {
	    flex: 0 0 100%;
	    max-width: 100%;
	    margin: 0;
	    margin-top: 15px;
	}
	.cart_row {
	    flex-direction: column;
	}
	.g-total {
	    margin-top: 15px;
	    padding-top: 15px;
	}

	.heading-type2 {
	    font-size: 16px;
		margin-bottom: 5px;
	}
	.rating_r_space {
		padding: 0;
		margin-top: 20px;
	}
	.search-wrap .form-group {
	    flex: 0 0 100%;
	    max-width: 100%;
	    margin: 10px 0 0;
	}
	.cart_body .search-wrap .form-group {
	    display: none;
	}

	.menu-right {
	    margin-left: 0;
	}
	.middle_header .container {
	    flex-wrap: wrap;
	    position: relative;
	}
	.search-wrap {
	    flex-wrap: wrap;
	}
	.head_icon img {
	    max-height: 20px;
	}
	.head-right {
	    position: absolute;
	    top: 6px;
	    right: 15px;
	}
	.logo {
	    margin-left: 40px;
	    max-width: 120px;
	}
	.head-right ul li {
	    margin: 0 10px;
	}
	.country-slt {
	    margin: 0 5px;
	}
	.navbar-toggler {
	    position: absolute;
	    top: -92px;
	    border: 0;
	    padding: 0;
	    left: 10px;
	    display: block;
	}
	.top_header {
		display: none;
	}
	.desktop_menu {
	    display: none;
	}
	.head_top_cont {
	     display: none; 
	}
	
	.navbar-nav {
	    flex-direction: column;
	}
	.navbar-collapse {
	    flex-basis: 100% !important;
	}
	.navbar-light .navbar-nav .nav-link {
	    padding: 10px 0;
	    font-size: 14px;
	}
	.open_nav:after {
	    position: fixed;
	    content: '';
	    height: 100vh;
	    background: rgb(0 0 0 / 65%);
	    width: 100%;
	    top: 0;
	    z-index: 1111;
	}
	.open_nav {
	    overflow: hidden;
	}
	
	#navbarSupportedContent {
	    position: fixed;
	    top: 0;
	    background: #fff;
	    height: 100vh;
	    padding: 20px 20px 40px 20px;
	    box-shadow: 0 0 10px #b1b1b1;
	    left: -105%;
	    transition: .5s;
	    width: 100%;
	    overflow: auto;
	    z-index: 111111;
	    max-width: 320px;
	    flex-direction: column;
	    flex-basis: 100% !important;
	    display: block;
	}
	.close-ic {
	    display: block;
	    text-align: right;
	    margin-top: -10px;
	}
	.others-option .offer a {
	    justify-content: center;
	}
	.others-option {
		width: 100%;
	}
	.open_nav #navbarSupportedContent {
	    left: 0;
	    transition: .5s;
	}
	.navbar-light .navbar-nav .nav-link {
	    padding: 20px 0;
	    border-bottom: solid 1px #afafaf;
	    display: flex;
	    align-items: center;
	    color: #000;
	    font-weight: 400;
	    font-size: 16px;
	}
	.mobile_menu_ic {
	    margin-right: 10px;
		display: inline-flex;
	}
	.newsletter-wrap .input-group {
	    width: 100%;
	    display: block;
	    margin-bottom: 10px;
	}
	.newsletter-wrap .input-group label {
	    margin-bottom: 5px;
	}
	.footer-social-sec {
	    text-align: center;
	}
	.footer-social-sec ul {
	    margin: 0 auto;
	    justify-content: center;
	    padding-left: 0;
	}
	.foote-cont {
	    margin-bottom: 20px;
	}
	.top-footer [class*="col-"]:first-child:after {
		display: none;
	}
	.middle-footer [class*="col-"]:last-child .foote-cont {
	    margin-bottom: 0;
	}
	.payment-img {
	    text-align: center;
	}
	.copyright-cont {
	    text-align: center;
	    margin-bottom: 5px;
	}
	.search-wrap .form-control {
	    min-width: 100% !important;
	    font-size: 13px;
	    padding: 5px 35px;
	    min-height: 35px;
	}
	.heading-type1, .heading-type1 a {
	    font-size: 18px;
	}
	.head_top_cont {
	    display: none;
	}
	.timers ul li {
	    font-size: 15px;
	}
	.timers ul li span {
	    font-size: 15px;
	}
	.timers {
	    padding: 5px 10px;
	}
	.brand-tag {
	    font-size: 12px !important;
	}
	.brand-cont p {
	    font-size: 11px;
	}
	.brand-cont {
	    bottom: 5px;
	    width: calc(100% - 15px);
	    margin: 0 7.5px;
	    padding: 5px 10px;
	}
	.inner-tit .form-group {
	    margin-top: 0;
	}
	.payment_select .custom_slt {
	    flex-wrap: wrap;
	    flex-direction: column;
	}	
	.cart_right .cart-prd-img {
	    flex: 0 0 120px;
	    max-width: 120px;
	}
	.payment_select .custom_slt {
	    flex-direction: row;
	}
	.payment_select label.custom_radio_b {
	    margin-right: 20px;
	    padding-top: 3px;
	}
	span.pay-icon img {
	    max-width: 35px;
	}
	.pay-option {
	    font-size: 12px;
	    padding-top: 2px;
	    margin-left: 5px;
	}
	span.pay-icon {
	    margin-left: 2px;
	}
	.offer-img img {
		height: 100%;
	}
	.offer-img {
	    padding-bottom: 30%;
	}
	.banner-seller a{
		padding-bottom: 30%
	}
	.bottom_header {
		border-bottom: 0;
		padding:0;
	}
	.terms_privacy {
	    display: flex;
    	justify-content: center;
	    margin-top: 20px;
	}
	.terms_privacy a {
		font-size: 13px;
		padding: 0 10px !important;
		border-right: solid 1px #afafaf;
		color: #000;
		text-decoration: none;
		line-height: 1.2;
		border-bottom: 0 !important;
	}
	.terms_privacy a:last-child {
	    border-right: 0;
	    padding-right: 0;
	}
	.terms_privacy a:first-child {
	    padding-left: 0;
	}
	.bottom-footer {
	    margin-bottom: 65px;
	}
	.subscribe-sec {
	 	   margin-top: 25px;
	    padding-top: 25px;
	}
	.accordion-description .nav-tabs {
	    flex-wrap: unset;
	    overflow: auto;
	    padding-bottom: 8px
	}

	.accordion-description nav {
	    overflow-x: scroll;
	}

	.accordion-description .nav-tabs .nav-link {
	    white-space: nowrap;
	}
	.combo_prd.wishlist_item .col_5:nth-child(3) .arrival-prd ~ span {
		display: flex;
	}
	.combo_prd.wishlist_item .col_5:nth-child(2) .arrival-prd ~ span, .combo_prd.wishlist_item .col_5:nth-child(4) .arrival-prd ~ span, .combo_prd.wishlist_item .col_5:last-child .arrival-prd ~ span {
		display: none;
	}
	body.cart_page .search-wrap {
		display: none;
	}

	.cart_page .discount-cls {
		display: none;
	}

	.cart_page .navbar-toggler {
		top: -45px;
	}

	.cart_page 
	.cart_title {
		top: -58px;
	}
	
	button.btn.btn_cross_inner {
		font-size: 18px;
		height: 27px;
		max-width: 27px;
    	min-width: 27px;
		width: 27px;
	}
}
@media screen and (max-width: 575.98px) {
	[class*="col-"] {
		padding: 0 5px;
	}
	.row {
		margin: 0 -5px;
	}
	
	.sale-prd-cont h4 {
		font-size: 28px;
	}
	
	.sale-prd-cont span.upto {
		font-size: 16px;
	}
	
	span.off {
		font-size: 14px;
	}
	.wallet_amount {
		margin: 0 -10px
	}
	.order_wrap:first-child .order-body {
		margin-top: 0;
	}
	.style-module_tooltip__tKc3i {
		font-size: 12px;
		padding: 5px 10px !important;
	}
	.perfume_inner_title h3 {
		font-size: 16px;
	}
	.accordion-inner ul li span {
		font-size: 13px;
	} 
	.cart_right_title {
		padding: 10px;
	}
	
	.cart_price {
		padding: 0 10px;
	}
	
	.gift-code {
		padding: 0 10px;
	}
	
	.g-total {
		padding: 10px;
		margin-top: 10px;
	}
	.ballance_right { 
		flex: 0 0 65px
	}
	.cart-product {
		flex-wrap: wrap;
	}
	.order-body .card-prd-cont {
		flex: 0 0 calc(100% - 85px);
	}
	.item_total_price {
		flex-direction: inherit;
		width: 100%;
		justify-content: space-between;
		flex: 0 0 100%;
		align-items: center;
	}
	.order_wrap .cart_prd_right {
		margin: 0;
		justify-content: space-between !important;
		flex: 0 0 100%;
		padding-left: 0;
		margin-top: 10px;
	}
	.order-actions {
		margin-left: auto;
		margin-top: 0;
	}
	.prd-quantity span {
		font-size: 12px;
	}
	.order-id {
		font-size: 16px;
	}
	.date_time {
		font-size: 11px;
	}
	.order_payment {
		font-size: 11px;
	}
	.order_right .order-status .btn_primary {
		margin-top: 5px;
		padding: 7px 5px !important;
		min-height: unset !important;
		height: unset;
	}
	.order-btn {
		font-size: 11px !important;
		padding: 4px 12px;
	}
	.order_wrap {
		padding: 10px;
	}
	.order-body .cart-product {
		padding: 10px;
	}
	.search_dropdown_cls li a {
		font-size: 12px;
		margin-left: 5px;
	}
	.search_dropdown_cls li img {
		flex: 0 0 30px;
		max-width: 30px;
	}
	.item-remove img {
		max-height: 20px;
	}
	.cart_left .prd-quantity .q_btn_group button {
		height: 20px;
		width: 20px;
		font-size: 18px;
	}
	.card-prd-cont .prd-quantity {
		margin: 2px 0;
	}
	.cart_left .prd-quantity .q_btn_group input {
		height: 20px;
		font-size: 12px;
		padding: 0;
	}
	.app-icon img {
		max-width: 22px;
	} 
	.btn {
		padding: 10px 15px;
	}
	.share-option .dropdown-toggle::after {
		display: none;
	}
	.category-sec .banner-img {
		width: 90px;
		height: 90px;
		border-width: 1px;
		padding: 10px;
	}
	.user-contact-dtl {
	    flex-wrap: wrap;
	}

	.user-contact-dtl div {
	    border: 0;
	    padding-top: 10px;
	    width: 100%;
	}

	.user-contact-dtl div:first-child {
	    padding-top: 0;
	    margin-top: 0;
	}
	.user-profile-dtl h3 {
	    font-size: 18px;
	    margin-bottom: 10px;
	}
	.wallet_cont p {
		font-size: 13px
	}
	.date_wrap p {
	    font-size: 14px;
	}
	.order-btn {
	    padding: 5px 12px;
	}
	.wallet_amount {
	    flex-direction: column;
	}
	.wallet_bal {
	    border: 0;
	}
	.prd-title h2 {
	    font-size: 16px;
	}
	.prd-price-wrap .arrival-price p {
	    font-size: 15px;
	    margin-bottom: 0;
	}

	.prd-price-wrap .arrival-price p.discount-price {
	    font-size: 13px;
	    margin-top: 1px;
	}

	.main-prd-left p {
	    font-size: 14px;
	}
	.banner-seller a img{
		position: unset;
		transform: unset;
	}
	.fragrances-banner a img {
		position: unset;
		transform: unset;
	}
	.title-main {
	    margin-bottom: 5px;
	}
	.offer-img {
	    border-radius: 10px;
	}
	.timers {
	    border-radius: 10px 0 3px 0;
	}
	.brand-img {
	    border-radius: 10px;
	}
	.brand-cont {
	    border-radius: 5px;
	}
	.bg-1 {
	    border-radius: 10px;
	}
	.prd-box {
	    border-radius: 10px;
	}
	.prd-img a {
	    border-radius: 10px;
	}
	.combo-img a {
	    border-radius: 10px;
	}
	.price_bg {
	    border-radius: 5px;
	}
	.price-sec .row {
	    margin: 0 -5px;
	}
	.price-sec .row [class*="col-"] {
	    padding: 0 5px;
	    margin: 5px 0;
	}
	.bg-2 {
	    border-radius: 10px;
	}
	.flower-bg {
	    border-radius: 10px;
	}
	.flower-img a img {
	    border-radius: 10px;
	}
	.fragrances-banner a {
	    border-radius: 10px;
	}
	.arrival-bg {
	    border-radius: 10px;
	}
	.banner-seller a {
	    border-radius: 10px;
	    padding-bottom: 0
	}
	.gift-card-img a {
	    border-radius: 10px;
	}
	.app-dtl-wrap {
	    border-radius: 10px;
	}
	.sale-prd a {
	    border-radius: 10px;
	}
	.gift-banner a {
	    border-radius: 10px;
	}
	.fragrances-img a {
	    border-radius: 10px;
	}
	.accessories-img a {
	    display: block;
	    border-radius: 15px;
	    overflow: hidden;
	}
	.cate-banner a {
	    border-radius: 15px;
	}
	.banner-sec .container .banner-img {
	    border-radius: 10px;
	}
	.owl-carousel .owl-nav div {
	    height: 25px;
	    width: 25px;
	    top: calc(50% - 12.5px);
	}
	.thankyou_sec_in h1 {
		font-size: 24px;
		margin-bottom: 8px;
		margin-top: 15px;
	}
	.thankyou_sec_in p {
		margin: 5px 0;
	    font-size: 15px;
	}
	.thankyou_sec_in {
	    max-width: 220px;
	}
	.prd_size_label label.custom_radio_b span.checkmark {
	    min-width: 60px;
	    font-size: 12px;
	}
	.add-btn-grp .btn {
	    padding: 10px 10px;
	}
	.accordion-description .nav-tabs .nav-link {
	    font-size: 14px;
	    padding: 12px 10px;
	}	   
	.tab-content .tab-pane p {
	    font-size: 14px;
	    line-height: 1.6;
	}
	.prd-inner-bg p {
	    font-size: 13px;
	}
	.accordion-inner ul li span {
	    min-width: 134px;
	    flex: 0 0 134px;
	    max-width: 134px;
	}
	.cart_price ul li{
		margin-bottom: 5px
	}
	.banner-sec .owl-carousel .owl-nav .owl-next {
	    right: 10px !important;
	    left: auto !important;
	}
	.banner-sec .owl-carousel .owl-nav div {
	    left: 10px !important;
	}
	.category-sec .col_1 {
	    flex: 0 0 calc(100% / 5);
	    max-width: calc(100% / 5);
	}
	.inner-tit .form-group {
	    margin-top: 0;
	}
	.inner-tit h3 {
	    flex: 0 0 100%;
	}
	.inner-tit {
	    flex-wrap: wrap;
	}
	.inner-tit .search-wrap {
	    margin-left: 0;
	    width: 100%;
	    margin-top: 10px;
	}
	.edit_add {
	    font-size: 14px;
	}
	.cart_login_btn {
		flex: 0 0 70px;
	}
	.cart_login_btn .btn.btn_primary {
	    padding: 5px 5px;
	}
	.address_ic img {
	    max-width: 35px;
	}
	.order-body .cart-prd-img {
	    flex: 0 0 75px;
	    max-width: 75px;
		height: 75px;
	}
	.cart-prd-img img {
	    max-height: 75px;
	    text-align: center;
	    margin: 0 auto;
	    display: table;
	}
	.item-remove {
	    flex: 0 0 65px
	}
	.item_total_price h3 {
	    font-size: 12px;
	}
	.category-sec .col_1 {
	    flex: 0 0 calc(100% / 3);
	    max-width: calc(100% / 3);
	}
	.category-sec .col_1 a .brand_img {
		height: 110px;
		width: 110px;
		text-align: center;
		margin: 0 auto;
	}
	.header_list a {
	    font-size: 0;
	}
	.header_right {
	    justify-content: space-between;
	    margin-left: 0;
	    width: 100%;
	}
	.header_contact_info {
	    justify-content: space-between;
	}
	.disc-code p {
	    font-size: 12px;
	}

	.disc-code p .coupon-code {
	    font-size: 13px;
        padding: 2px 5px;
	}
	.price-dtl {
	    font-size: 26px;
	}
	.price-desc {
	    font-size: 14px;
	}
	.under {
	    font-size: 12px;
	}
	.fragrances-banner a {
	    padding-bottom: 0;
	}
	.select select {
	    font-size: 12px;
	}
	.heading-type1, .heading-type1 a {
	    font-size: 16px;
	}

	.heading-type2 {
	    font-size: 14px;
	}
	.category-sec .owl-prev {
	    height: 25px !important;
	    width: 25px !important;
	}
	.category-sec .owl-carousel .owl-nav button.owl-next {
	    height: 25px !important;
	    width: 25px !important;
	}
	.category-sec .owl-carousel .owl-nav button {
	    top: calc(50% - 12.5px);
	}
	.q_btn_group button {
	    font-size: 24px;
	}
	.order-status .order-actions {
		flex: 0 0 100%;
		margin-left: 0 !important;
	}
	.order_download.d-flex {
		flex-wrap: wrap;
	}
	.order-status .order-actions .btn.btn_primary {
		margin-top: 10px;
	}

	.cart-product .q_btn_group {
		max-width: 80px;
	}
	.cart-product .cart_left .prd-quantity .q_btn_group input {
		max-width: 20px;
	}
	
  .cart-product .card_price_main {
    display: block;
  }
  .cart-product .card_price_main .arrival-price {
    white-space: nowrap;
  }
  .cart-product .arrival-price p.discount-price span {
	font-size: 13px;
  }
  .cart_left .accordion-body {
	padding: 10px;
  }
}
@media screen and (max-width: 480.98px) {
	.terms_privacy a.nav-link {
		font-size: 11px !important;
	}
	.prd-info-cont ul li {
		flex: 0 0 100%;
	}
	.date_wrap p {
		font-size: 11px;
	}
	.wallet_cont p {
		font-size: 11px;
	}
	.wallet_cont h5 {
		font-size: 13px;
	}
	.wishlist_item [class*="col-"] {
	    flex: 0 0 50%;
	    max-width: 50%;
	}
	/* Product cont CSS*/
	.rate-dtl {
	    font-size: 12px;
	    padding-top: 0;
	}
	.ratting-icon img {
	    max-width: 10px;
	}
	.arrival-title h3 {
	    font-size: 14px;
	}
	.arrival-title p {
	    font-size: 12px;
		margin-top: 3px;
	}
	.arrival-price p {
	    font-size: 11px;
	    line-height: 1.2;
	}
	.arrival-price p.discount-price {
	    font-size: 11px !important;
		padding-bottom: 2px;
	}

	.arrival-cont {
    	padding-top: 5px;
	}
	.ratting-icon {
	    line-height: 1;
	}
	.prd-add-option a {
	    min-height: 30px;
	}
	.fav_wrap a {
	    height: 30px;
	    width: 30px !important;
	}
	/* .arrival-prd-img a {
	    padding-bottom: 130%;
	} */
	/* Product cont CSS*/


	.prd-perfume-box h4 {
	    font-size: 10px;
	}
	.rating-left {
	    flex: 0 0 100%;
	    max-width: 100%;
		padding: 0
	}
	.rating-right {
		flex: 0 0 100%;
		max-width: 100%;
		padding: 0;
		border-left: 0
	}
	.rating-sec {
	    flex-wrap: wrap;
	}
	.review-right p {
	    font-size: 13px;
	}
	.accordion-description .nav-tabs .nav-link {
	    font-size: 14px;
	    padding: 12px 10px;
	}
	.cart_right .cart-prd-img {
	    flex: 0 0 60px;
	    max-width: 60px;
	}
	.payment_select .custom_slt {
	    flex-direction: column;
	}
	.product_right_s [class*="col-"] {
	    flex: 0 0 50%;
	    max-width: 50%;
	}
	.btn_primary {
	    font-size: 11px;
	}
	.essentials-box h4 {
	    font-size: 14px;
	}
	.timers ul li span {
	    font-size: 12px;
	}
	.timers ul li {
	    font-size: 12px;
	}
	.timers {
	    padding: 2px 10px;
	}
	.app-box .app-icon img {
		max-height: 30px;
	}
}
@media screen and (max-width: 420.98px) {
	.copyright-cont h4 {
	    font-size: 12px;
	}
	.category-sec .col_1 {
	    flex: 0 0 calc(100% / 2);
	    max-width: calc(100% / 2);
	}
	.category-sec .col_1 a .brand_img {
		height: 120px;
		width: 120px;
		text-align: center;
		margin: 0 auto;
	}
	.app_title h3 {
		font-size: 12px;
		margin-top: 7px;
		min-height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.app-dtl-wrap {
	    padding: 10px;
	}
	.disc-code p .coupon-code {
	    margin: 0 5px;
	}
}