/* THEMES */

/********** Theme: dark **********/
/* Font styles */
.flipdown.flipdown__theme-dark {
  font-family: sans-serif;
  font-weight: bold;
}
/* Rotor group headings */
.flipdown.flipdown__theme-dark .rotor-group-heading:before {
  color: #000000;
}
/* Delimeters */
.flipdown.flipdown__theme-dark .rotor-group:nth-child(n+2):nth-child(-n+3):before,
.flipdown.flipdown__theme-dark .rotor-group:nth-child(n+2):nth-child(-n+3):after {
  background-color:#f9207b;
}
/* Rotor tops */
.flipdown.flipdown__theme-dark .rotor,
.flipdown.flipdown__theme-dark .rotor-top,
.flipdown.flipdown__theme-dark .rotor-leaf-front {
  color: #FFFFFF;
  background-color: #f9207b;
}
/* Rotor bottoms */
.flipdown.flipdown__theme-dark .rotor-bottom,
.flipdown.flipdown__theme-dark .rotor-leaf-rear {
  color: #EFEFEF;
  background-color: #f9207b;
}
/* Hinge */
.flipdown.flipdown__theme-dark .rotor:after {
  border-top: solid 1px #ffffffb8;
}

/********** Theme: light **********/
/* Font styles */
.flipdown.flipdown__theme-light {
  font-family: sans-serif;
  font-weight: bold;
}
/* Rotor group headings */
.flipdown.flipdown__theme-light .rotor-group-heading:before {
  color: #EEEEEE;
}
/* Rotor tops */
.flipdown.flipdown__theme-light .rotor,
.flipdown.flipdown__theme-light .rotor-top,
.flipdown.flipdown__theme-light .rotor-leaf-front {
  color: #fff;
  background-color: #f9207b;
}
/* Rotor bottoms */
.flipdown.flipdown__theme-light .rotor-bottom,
.flipdown.flipdown__theme-light .rotor-leaf-rear {
  color: #fff;
  background-color: #f9207b;
}
/* Hinge */
.flipdown.flipdown__theme-light .rotor:after {
  border-top: solid 1px #ffffff;
}

/* END OF THEMES */

.flipdown {
}

.flipdown .rotor-group {
  position: relative;
  float: left;
  padding-right: 6px;
}

.flipdown .rotor-group:last-child {
  padding-right: 0;
}

.flipdown .rotor-group-heading:before {
  display: block;
  text-align: center;
}

.flipdown .rotor-group:nth-child(1) .rotor-group-heading:before {
  content: attr(data-before);
}

.flipdown .rotor-group:nth-child(2) .rotor-group-heading:before {
  content: attr(data-before);
}

.flipdown .rotor-group:nth-child(3) .rotor-group-heading:before {
  content: attr(data-before);
}

.flipdown .rotor-group:nth-child(4) .rotor-group-heading:before {
  content: attr(data-before);
}

.flipdown .rotor-group:before {
    content: '';
    position: absolute;
    bottom: 18px;
    left: -4px;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: #f9207b;
}

.flipdown .rotor-group:after {
    content: '';
    position: absolute;
    bottom: 7px;
    left: -4px;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: #f9207b;
}
.flipdown .rotor-group:first-child:before, .flipdown .rotor-group:first-child:after {
    display: none;
}
.flipdown .rotor {
  position: relative;
  float: left;
  width: 25px;
  height: 30px;
  margin: 0px 2px 0px 0px;
  border-radius: 4px;
  font-size: 20px;
  text-align: center;
  perspective: 200px;
}

.flipdown .rotor:last-child {
  margin-right: 0;
}

.flipdown .rotor-top,
.flipdown .rotor-bottom {
  overflow: hidden;
  position: absolute;
  width: 25px;
  height: 15px;
}

.flipdown .rotor-leaf {
  z-index: 1;
  position: absolute;
  width: 25px;
  height: 30px;
  transform-style: preserve-3d;
  transition: transform 0s;
}

.flipdown .rotor-leaf.flipped {
  transform: rotateX(-180deg);
  transition: all 0.5s ease-in-out;
}

.flipdown .rotor-leaf-front,
.flipdown .rotor-leaf-rear {
  overflow: hidden;
  position: absolute;
  width: 25px;
  height: 15px;
  margin: 0;
  transform: rotateX(0deg);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.flipdown .rotor-leaf-front {
  line-height: 30px;
  border-radius: 4px 4px 0px 0px;
}

.flipdown .rotor-leaf-rear {
  line-height: 0px;
  border-radius: 0px 0px 4px 4px;
  transform: rotateX(-180deg);
}

.flipdown .rotor-top {
  line-height: 30px;
  border-radius: 4px 4px 0px 0px;
}

.flipdown .rotor-bottom {
  bottom: 0;
  line-height: 0px;
  border-radius: 0px 0px 4px 4px;
}

.flipdown .rotor:after {
  content: '';
  z-index: 2;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 25px;
  height: 15px;
  border-radius: 0px 0px 4px 4px;
}

/*@media (max-width: 550px) {

  .flipdown {
    width: 312px;
    height: 53px;
  }

  .flipdown .rotor {
    font-size: 2.2rem;
    margin-right: 3px;
  }

  .flipdown .rotor,
  .flipdown .rotor-leaf,
  .flipdown .rotor-leaf-front,
  .flipdown .rotor-leaf-rear,
  .flipdown .rotor-top,
  .flipdown .rotor-bottom,
  .flipdown .rotor:after {
    width: 30px;
  }

  .flipdown .rotor-group {
    padding-right: 8px;
  }

  .flipdown .rotor-group:last-child {
    padding-right: 0px;
  }

  .flipdown .rotor-group-heading:before {
    font-size: 0.8rem;
    height: 15px;
    line-height: 15px;
  }

  .flipdown .rotor-group:nth-child(n+2):nth-child(-n+3):before,
  .flipdown .rotor-group:nth-child(n+2):nth-child(-n+3):after {
    left: 69px;
  }

  .flipdown .rotor-group:nth-child(n+2):nth-child(-n+3):before {
    bottom: 13px;
    height: 8px;
    width: 8px;
  }

  .flipdown .rotor-group:nth-child(n+2):nth-child(-n+3):after {
    bottom: 29px;
    height: 8px;
    width: 8px;
  }

  .flipdown .rotor-leaf-front,
  .flipdown .rotor-top {
    line-height: 30px;
  }

  .flipdown .rotor-leaf,
  .flipdown .rotor {
    height: 30px;
  }

  .flipdown .rotor-leaf-front,
  .flipdown .rotor-leaf-rear,
  .flipdown .rotor-top,
  .flipdown .rotor-bottom,
  .flipdown .rotor:after {
    height: 19px;
  }
}
*/